import {Col, Container, Row} from "react-bootstrap";
import background_4 from "../../assets/background/pau.jpg";
import React from "react";
import {useNavigate} from "react-router-dom";
import ProjectColors from "../../Colors";

export default function ArtistSelect() {
    const navigate = useNavigate()
    return <>
        <div style={{
            backgroundImage: 'url(' + background_4 + ')',
            backgroundSize: 'cover',
            height: '100%',
            width: '100%',

        }}

             title={'Maçaneta antiga enferrujada próxima da câmera com o fundo desfocado, a porta atrás é de cor vermelho escuro'}>
            <main role="main">
                <Container fluid className={'vh-100'}>

                    <Row className={'align-middle'}>

                        <Col sm={{span: 3}} className={'d-flex align-items-end p-4 '} onClick={() => {

                            navigate('/artist')
                        }}>

                            <h1 style={{
                                color: ProjectColors.getInstance().White,
                                fontSize: '1.75rem',
                                lineHeight: 1.1,
                            }}> &lt; Casa de artista</h1>

                        </Col>
                        <Col sm={{span: 3}} className={'d-flex align-items-end p-4 page_ana page_leticia'}
                             style={{height: '50vh'}}
                             onClick={() => {
                                 navigate('/artist/leticia')
                             }}
                        >
                            <span role="img"
                                  aria-label="Letícia é uma mulher negra de cabelos crespos, está sentada em uma escrivaninha no seu escritório"> </span>


                            <h1 className={'text_hover'}
                                style={{
                                    color: ProjectColors.getInstance().White,
                                    fontSize: '1.75rem',
                                    lineHeight: 1.1,
                                }}>Letícia</h1>

                        </Col>
                        <Col sm={{span: 3}} className={'d-flex align-items-end p-4 page_ana'} style={{height: '50vh'}}
                             onClick={() => {
                                 navigate('/artist/ana')
                             }}>

                            <span role="img"
                                  aria-label="Ana é uma mulher branca de cabelo castanho curto com mechas rosas, está sentada manuseando um album de fotos"> </span>


                            <h1 style={{
                                color: ProjectColors.getInstance().White,
                                fontSize: '1.75rem',
                                lineHeight: 1.1,
                            }}>Ana</h1>

                        </Col>


                        <Col sm={{span: 3}} className={'d-flex align-items-end p-4 page_ana page_marina'}
                             style={{height: '50vh'}}
                             onClick={() => {
                                 navigate('/artist/marina')
                             }}
                        >

                            <span role="img"
                                  aria-label="Ana é uma mulher branca de cabelo castanho curto com mechas rosas, está sentada manuseando um album de fotos"> </span>


                            <h1 style={{
                                color: ProjectColors.getInstance().White,
                                fontSize: '1.75rem',
                                lineHeight: 1.1,
                            }}>Marina</h1>

                        </Col>

                    </Row>

                    <Row className={'align-middle'}>


                        <Col sm={{span: 3}} className={'d-flex align-items-end p-4 page_ana page_renata'}
                             style={{height: '50vh'}}
                             onClick={() => {
                                 navigate('/artist/renata')
                             }}
                        >


                            <h1 style={{
                                color: ProjectColors.getInstance().White,
                                fontSize: '1.75rem',
                                lineHeight: 1.1,
                            }}>Renata</h1>

                        </Col>
                        <Col sm={{span: 3}} className={'d-flex align-items-end p-4 page_ana page_tatiane'}
                             style={{height: '50vh'}}
                             onClick={() => {
                                 navigate('/artist/tatiane')
                             }}
                        >


                            <h1 style={{
                                color: ProjectColors.getInstance().White,
                                fontSize: '1.75rem',
                                lineHeight: 1.1,
                            }}>Tatiana</h1>

                        </Col>
                        <Col sm={{span: 3}} className={'d-flex align-items-end p-4 page_ana page_julia'}
                             style={{height: '50vh'}}
                             onClick={() => {
                                 navigate('/artist/julia')
                             }}
                        >


                            <h1 style={{
                                color: ProjectColors.getInstance().White,
                                fontSize: '1.75rem',
                                lineHeight: 1.1,
                            }}>Julia</h1>

                        </Col>

                        <Col sm={{span: 3}} className={'d-flex align-items-end p-4 page_ana page_caisa'}
                             style={{height: '50vh'}}
                             onClick={() => {
                                 navigate('/artist/caisa')
                             }}
                        >


                            <h1 style={{
                                color: ProjectColors.getInstance().White,
                                fontSize: '1.75rem',
                                lineHeight: 1.1,
                            }}>Caísa</h1>

                        </Col>

                    </Row>

                </Container>
            </main>
        </div>
    </>

}