import MainCustomNavbar from "../../components/MainNavbar";
import image_0 from "../../assets/artist_pictures/julia/picture_0.webp";
import image_5 from "../../assets/artist_pictures/julia/picture_1.webp";
import carrousel_3 from "../../assets/artist_pictures/julia/picture_3.webp";
import carrousel_4 from "../../assets/artist_pictures/julia/picture_4.webp";
import carrousel_5 from "../../assets/artist_pictures/julia/picture_5.webp";
// import casa_video from '../../assets/videos/julia/casa.mp4'
// import mulher_video from '../../assets/videos/julia/mulher.mp4'
// import pandemia_video from '../../assets/videos/julia/pandemia.mp4'
// import criacao_video from '../../assets/videos/julia/criacao.mp4'
// import arte_video from '../../assets/videos/julia/performance.mp4';
import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect} from "react";
// @ts-ignore
import marina from "../../assets/artist_pictures/caisa/picture_0.webp";
// @ts-ignore
import ProjectColors from "../../Colors";
import Arrow from "../../assets/components/side_arrow.png";
import {useNavigate} from "react-router-dom";
import {Carousel} from "react-responsive-carousel";
import frame_0 from "../../assets/artist_pictures/julia/frame_0.webp";
import frame_1 from "../../assets/artist_pictures/julia/frame_1.webp";
import houses from "../../assets/components/houses.png";
import frame_2 from "../../assets/artist_pictures/julia/frame_2.webp";
import frame_4 from "../../assets/artist_pictures/julia/picture_6.webp";
import old_house_2 from "../../assets/artist_pictures/julia/old_house.webp";
import frame_3 from "../../assets/artist_pictures/julia/frame_3.webp";
import carrousel_1 from "../../assets/artist_pictures/julia/picture_2.webp";
import carrousel_2 from "../../assets/artist_pictures/julia/picture_12.webp";

import VideoPrefix from "../../VideoPrefix";

const casa_video = VideoPrefix + "julia/casa.mp4";
const mulher_video = VideoPrefix + "julia/mulher.mp4";
const pandemia_video = VideoPrefix + "julia/pandemia.mp4";
const criacao_video = VideoPrefix + "julia/criacao.mp4";
const arte_video = VideoPrefix + "julia/performance.mp4";

export default function ProfileJulia() {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <MainCustomNavbar activeTab1={true}/>

            <main
                role="main"
                style={{
                    backgroundColor: ProjectColors.getInstance().isHighContrast
                        ? ProjectColors.getInstance().Black
                        : ProjectColors.getInstance().White,
                }}
            >
                <Container
                    fluid
                    style={{
                        height: "100%",
                        padding: 0,
                    }}
                >
                    {/*<Row*/}
                    {/*    className={'align-items-center px-3'} style={{height: '100vh', width: '100vw'}}>*/}

                    {/*    <Col xs={{span: 12}} md={{span: 12}}>*/}

                    {/*        <h1 style={{fontFamily: 'Active Regular', fontWeight: 'bold', textAlign: 'center'}}>Ana Luíza</h1>*/}

                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <div
                        style={{
                            background: "url(" + image_0 + ")",
                            backgroundSize: "cover",
                            height: "100%",
                            width: "100%",
                            backgroundAttachment: "fixed",
                            backgroundPosition: "75%",
                        }}
                    >
                        <div style={{height: "20vh", width: "100vw"}}></div>
                        <Row
                            style={{
                                height: "90vh",
                                width: "100vw",
                                padding: 0,
                            }}
                        ></Row>
                    </div>

                    {/*<Row className={'align-items-center px-3'} style={{marginTop: '10vh',}}>*/}

                    {/*    <Col xs={{span: 12}} md={{span: 12}}*/}
                    {/*        // className={'d-flex align-items-center'}*/}
                    {/*    >*/}

                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <Row style={{marginTop: 10}} className={"px-2"}>
                        <Col xs={{span: 12}} md={{span: 12, offset: 0}}>
                            <h5
                                style={{
                                    fontFamily: "Gotham",
                                    textAlign: "justify",
                                    textAlignLast: "justify",
                                    color: ProjectColors.getInstance().White,
                                    backgroundColor: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().Black
                                        : ProjectColors.getInstance().Orange,
                                    padding: "20px",
                                }}
                            >
                                Julia Henning é artista circense e de tudo um tanto no Coletivo
                                Instrumento de Ver. Quando não está em cena, pendurada ou
                                caminhando em garrafas, está dirigindo, produzindo, pesquisando
                                ou escrevendo sobre circo. Vem se dedicando ao estudo e à
                                prática de dramaturgias específicas das artes circenses. É
                                graduada em Psicologia e mestre em Artes Cênicas pela
                                Universidade de Brasília onde investigou a intermedialidade em
                                poéticas circenses contemporâneas. Tem formação em Dramaturgia
                                Circense pelas escolas ESAC (Bélgica) e CNAC (França) e em
                                Gestão Cultural pelo SENAC. Dirigiu, junto com Daniel Lacourt e
                                Beatrice Martins, o espetáculo “Bubuia”, do coletivo Antônia, e
                                com Beatrice Martins, o espetáculo “ÓTIMOMÁXIMO” do núcleo
                                Luneta. Fundou o Galpão Instrumento de Ver (ou Galpoa para os
                                íntimos), espaço cultural da Vila Planalto onde atualmente é
                                artista parceira. Está em circulação internacional com o
                                espetáculo “23 fragmentos desses últimos dias”, uma colaboração
                                Brasil-França com a condução e circografia de Maroussia Diaz
                                Verbèke.
                            </h5>
                            <div>
                                <img src={image_5} alt="" className={"img-fluid"}/>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "5vw", marginBottom: 0}} className={"px-2"}>
                        <Col xs={{span: 12}} md={{span: 6, offset: 3}}>
                            <h1
                                style={{
                                    fontFamily: "Active Regular",
                                    fontWeight: "bold",
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().White
                                        : ProjectColors.getInstance().Orange,
                                    textAlign: "justify",
                                }}
                            >
                                "Casa pra mim é o lugar pra onde eu volto, onde recupero as
                                energias, onde está a individualidade também, a intimidade".
                            </h1>
                        </Col>
                    </Row>

                    <Row style={{marginTop: "5vw"}} className={"px-3"}>
                        <Col xs={{span: 12}} md={{span: 10, offset: 1}}>
                            <div>
                                <h1
                                    className={"mb-4"}
                                    style={{
                                        fontFamily: "Active Regular",
                                        fontWeight: "bold",
                                        color: ProjectColors.getInstance().isHighContrast
                                            ? ProjectColors.getInstance().White
                                            : ProjectColors.getInstance().Green,
                                    }}
                                >
                                    Em casa
                                </h1>
                                <video
                                    className={"img-fluid"}
                                    style={{justifyContent: "center", display: "flex"}}
                                    controls
                                    poster={frame_0}
                                >
                                    <source src={casa_video} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </Col>
                    </Row>

                    <div
                        style={{
                            backgroundColor: ProjectColors.getInstance().isHighContrast
                                ? ProjectColors.getInstance().Black
                                : ProjectColors.getInstance().Brown,
                            width: "100%",
                            marginTop: "5vw",
                            paddingTop: "5vw",
                        }}
                    >
                        <Row style={{marginTop: 10}} className={"px-2"}>
                            <Col xs={{span: 12}} sm={{span: 10, offset: 1}}>
                                <Carousel
                                    infiniteLoop
                                    showIndicators={false}
                                    showStatus={false}
                                    autoPlay={false}
                                >
                                    {[
                                        <div>
                                            <img src={carrousel_1}/>
                                        </div>,
                                        <div>
                                            <img src={carrousel_2}/>
                                        </div>,

                                        <div>
                                            <img src={carrousel_3}/>
                                        </div>,

                                        <div>
                                            <img src={carrousel_4}/>
                                        </div>,
                                        <div>
                                            <img src={carrousel_5}/>
                                        </div>,
                                    ]}
                                </Carousel>
                            </Col>
                        </Row>
                        <Row
                            style={{marginTop: "5vw", paddingBottom: "5vw"}}
                            className={"px-3"}
                        >
                            <Col xs={{span: 12}} md={{span: 10, offset: 1}}>
                                <div>
                                    <h1
                                        className={"mb-4"}
                                        style={{
                                            fontFamily: "Active Regular",
                                            fontWeight: "bold",
                                            color: ProjectColors.getInstance().isHighContrast
                                                ? ProjectColors.getInstance().White
                                                : ProjectColors.getInstance().Green,
                                        }}
                                    >
                                        Mulher e espaço doméstico
                                    </h1>
                                    <video
                                        className={"img-fluid"}
                                        style={{justifyContent: "center", display: "flex"}}
                                        controls
                                        poster={frame_1}
                                    >
                                        <source src={mulher_video} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Row style={{marginTop: "5vw"}} className={"px-2"}>
                        <Col xs={{span: 12}}>
                            <div
                                className={"justify-content-center"}
                                style={{display: "flex"}}
                            >
                                <img
                                    src={houses}
                                    alt=""
                                    style={{height: "max(7vw, 70px)", backgroundColor: "white"}}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row style={{marginTop: "5vw"}} className={"px-3"}>
                        <Col xs={{span: 12}} md={{span: 10, offset: 1}}>
                            <div>
                                <h1
                                    className={"mb-4"}
                                    style={{
                                        fontFamily: "Active Regular",
                                        fontWeight: "bold",
                                        color: ProjectColors.getInstance().isHighContrast
                                            ? ProjectColors.getInstance().White
                                            : ProjectColors.getInstance().Green,
                                    }}
                                >
                                    Casa e pandemia
                                </h1>
                                <video
                                    className={"img-fluid m-auto"}
                                    style={{justifyContent: "center", display: "flex"}}
                                    controls
                                    poster={frame_2}
                                >
                                    <source src={pandemia_video} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "5vw"}} className={"px-2"}>
                        <Col
                            xs={{span: 12}}
                            md={{span: 4, offset: 4}}
                            className={"justify-content-center"}
                        >
                            <div
                                style={{display: "flex"}}
                                className={"justify-content-center"}
                            >
                                <img src={old_house_2} alt="" className={"img-fluid"}/>
                            </div>
                            <div>
                                <div style={{marginTop: 20}}>
                                    <p style={{
                                        fontSize: "max(1vw, 15px)",
                                        textAlign: "right",
                                        color: ProjectColors.getInstance().isHighContrast
                                            ? ProjectColors.getInstance().White
                                            : ProjectColors.getInstance().Black,
                                    }}>
                                        Apartamento alugado durante a pandemia na Vila Planalto
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row
                        style={{marginTop: "5vw", marginBottom: "5vw"}}
                        className={"px-3"}
                    >
                        <Col xs={{span: 12}} md={{span: 10, offset: 1}}>
                            <div>
                                <h1
                                    className={"mb-4"}
                                    style={{
                                        fontFamily: "Active Regular",
                                        fontWeight: "bold",
                                        color: ProjectColors.getInstance().isHighContrast
                                            ? ProjectColors.getInstance().White
                                            : ProjectColors.getInstance().Green,
                                    }}
                                >
                                    Processo artístico
                                </h1>
                                <video
                                    className={"img-fluid m-auto"}
                                    style={{justifyContent: "center", display: "flex"}}
                                    controls
                                    poster={frame_3}
                                >
                                    <source src={criacao_video} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </Col>
                    </Row>

                    {/*<Row style={{marginTop: 80}} className={'px-2'}>*/}
                    {/*    <Col xs={{span: 12}}>*/}
                    {/*        <div>*/}
                    {/*            <img src={image_5} alt="" className={'img-fluid'}/>*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}

                    {/*</Row>*/}

                    <Row
                        style={{
                            paddingTop: "5vw",
                            paddingBottom: "5vw",
                            backgroundColor: ProjectColors.getInstance().isHighContrast
                                ? ProjectColors.getInstance().Black
                                : ProjectColors.getInstance().Brown,
                        }}
                        className={"px-3"}
                    >
                        <Col xs={{span: 12}} md={{span: 5, offset: 1}}>
                            <h1
                                style={{
                                    fontFamily: "Active Regular",
                                    fontWeight: "bold",
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().White
                                        : ProjectColors.getInstance().Green,
                                }}
                            >
                                Conheça os meus trabalhos
                            </h1>
                            <br/>

                            <a
                                href={
                                    "https://drive.google.com/file/d/1-2-MhCFvgpoeEnEBrp52wC1EgRgJnIRH/view?usp=share_link"
                                }
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Video Ruína
                            </a>

                            <br/>
                            <a
                                href={"http://www.instrumentodever.com/"}
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Site{" "}
                            </a>

                            <br/>
                            <a
                                href="http://instagram.com/juliahjuliah"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Instagram
                            </a>

                            <br/>
                            <a
                                href="http://instagram.com/instrumentodever"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Instagram Instrumento de ver
                            </a>
                            <br/>
                            <a
                                href="https://drive.google.com/file/d/1ld6bMd-bqCkb0ZeKYe2ipD9t4Heczwrs/view?usp=sharing"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Portfólio
                            </a>
                            <br/>

                            <a
                                href="https://vimeo.com/344452081"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Vídeo Teoria de Tudo senha: teoria
                            </a>
                            <br/>

                            <a
                                href="https://www.youtube.com/watch?v=9wnGDuN882U&t=31s"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Vídeo O Que Me Toca é Meu Também
                            </a>
                            <br/>
                            <a
                                href="https://vimeo.com/200157949"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Vídeo O Homem Banco senha: Geringonça
                            </a>

                            <br/>
                            <a
                                href="https://youtu.be/B0aPcNDt-AQ"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Vídeo Vin|co
                            </a>
                            <br/>
                            <a
                                href="https://www.youtube.com/watch?v=SdKaz0iVHPc&t=5s"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                23 Fragmentos desses últimos dias
                            </a>
                            <br/>
                            <a
                                href="https://vimeo.com/449345643"
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().LightBlue
                                        : ProjectColors.getInstance().Green,
                                    fontSize: 28,
                                }}
                            >
                                Trailer 23 fragmentos
                            </a>
                            <br/>
                        </Col>

                        <Col xs={{span: 12}} md={{span: 5}}>
                            <div>
                                <video
                                    className={"img-fluid m-auto"}
                                    style={{justifyContent: "center", display: "flex"}}
                                    controls
                                    poster={frame_4}
                                >
                                    <source src={arte_video} type="video/mp4"/>
                                    Your browser does not support the video tag. Foto:João
                                    Saenger/Vídeo: Cícero Fraga
                                </video>

                                <div>
                                    <div style={{marginTop: 20}}>
                                        <p
                                            style={{
                                                fontSize: "max(1vw, 15px)",
                                                textAlign: "right",
                                                color: ProjectColors.getInstance().isHighContrast
                                                    ? ProjectColors.getInstance().White
                                                    : ProjectColors.getInstance().Green,
                                            }}
                                        >
                                            Foto:João Saenger/Vídeo: Cícero Fraga
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            backgroundImage: "url(" + marina + ")",
                            backgroundSize: "cover",
                            height: 500,
                        }}
                        className={"px-3"}
                    >
                        <Col xs={{span: 12}} className={"d-flex "}>
                            <h1
                                className={"my-4"}
                                style={{
                                    fontFamily: "Gotham Light",
                                    marginLeft: 30,
                                    fontSize: "70px",
                                    color: "white",
                                }}
                            >
                                Caísa
                                <img
                                    src={Arrow}
                                    width={50}
                                    onClick={() => navigate("/artist/caisa")}
                                    alt={"Seta laranja apontando para a direita"}
                                />
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    );
}
