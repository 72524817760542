import MainCustomNavbar from "../../components/MainNavbar";
import image_0 from "../../assets/artist_pictures/tatiane/picture_2.webp";
import image_1 from "../../assets/artist_pictures/tatiane/picture_1.webp";
import image_3 from "../../assets/artist_pictures/tatiane/picture_3.webp";
import image_4 from "../../assets/artist_pictures/tatiane/picture_0.webp";
import image_6 from "../../assets/artist_pictures/tatiane/picture_6.webp";
import image_7 from "../../assets/artist_pictures/tatiane/picture_7.webp";
import frame_5 from "../../assets/artist_pictures/tatiane/picture_7.webp";
import image_5 from "../../assets/artist_pictures/tatiane/picture_5.webp";
// import casa_video from '../../assets/videos/tatiane/casa.mp4'
// import mulher_video from '../../assets/videos/tatiane/mulher.mp4'
// import pandemia_video from '../../assets/videos/tatiane/pandemia.mp4'
// import criacao_video from '../../assets/videos/tatiane/criacao.mp4'
// import arte_video from "../../assets/videos/tatiane/performance.mp4";
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
// @ts-ignore
import marina from "../../assets/artist_pictures/julia/picture_0.webp";
// @ts-ignore
import ProjectColors from "../../Colors";
import Arrow from "../../assets/components/side_arrow.png";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import frame_2 from "../../assets/artist_pictures/tatiane/frame_3.webp";
import frame_3 from "../../assets/artist_pictures/tatiane/frame_4.webp";
import houses from "../../assets/components/houses.png";
import frame_1 from "../../assets/artist_pictures/tatiane/frame_2.webp";
import old_house_2 from "../../assets/artist_pictures/tatiane/old_photo.webp";
import frame_0 from "../../assets/artist_pictures/tatiane/frame_1.webp";

import VideoPrefix from "../../VideoPrefix";

const casa_video = VideoPrefix + "tatiane/casa.mp4";
const mulher_video = VideoPrefix + "tatiane/mulher.mp4";
const pandemia_video = VideoPrefix + "tatiane/pandemia.mp4";
const criacao_video = VideoPrefix + "tatiane/criacao.mp4";
const arte_video = VideoPrefix + "tatiane/performance.mp4";

export default function ProfileTatiane() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainCustomNavbar activeTab1={true} />

      <main
        role="main"
        style={{
          backgroundColor: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().Black
            : ProjectColors.getInstance().White,
        }}
      >
        <Container
          fluid
          style={{
            height: "100%",
            padding: 0,
          }}
        >
          {/*<Row*/}
          {/*    className={'align-items-center px-3'} style={{height: '100vh', width: '100vw'}}>*/}

          {/*    <Col xs={{span: 12}} md={{span: 12}}>*/}

          {/*        <h1 style={{fontFamily: 'Active Regular', fontWeight: 'bold', textAlign: 'center'}}>Ana Luíza</h1>*/}

          {/*    </Col>*/}
          {/*</Row>*/}

          <div
            style={{
              background: "url(" + image_0 + ")",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              backgroundAttachment: "fixed",
              backgroundPosition: "75%",
            }}
          >
            <div style={{ height: "20vh", width: "100vw" }}></div>
            <Row
              style={{
                height: "90vh",
                width: "100vw",
                padding: 0,
              }}
            ></Row>
          </div>

          {/*<Row className={'align-items-center px-3'} style={{marginTop: '10vh',}}>*/}

          {/*    <Col xs={{span: 12}} md={{span: 12}}*/}
          {/*        // className={'d-flex align-items-center'}*/}
          {/*    >*/}

          {/*    </Col>*/}
          {/*</Row>*/}

          <Row style={{ marginTop: 10 }} className={"px-2"}>
            <Col xs={{ span: 12 }} md={{ span: 12, offset: 0 }}>
              <h5
                style={{
                  fontFamily: "Gotham",
                  textAlign: "justify",
                  textAlignLast: "justify",
                  color: ProjectColors.getInstance().White,
                  backgroundColor: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().Black
                    : ProjectColors.getInstance().Orange,
                  padding: "20px",
                }}
              >
                Tatiana Reis nasceu em Brasília onde trabalha e reside. É mãe e
                artista visual. Historiadora da Arte formada pela Universidade
                de Brasília, trabalha como curadora e fotógrafa. Sua produção
                tem origem em memória, cartografia afetiva, feminismos e
                maternagens. Após a experiência com a maternidade, começou a
                documentar o puerpério, o corpo materno e sua relação com as
                filhas através de fotografias, textos, desenhos e esculturas. É
                uma das coordenadoras do “Arte e Maternagem”, coletiva e
                mapeamento de artistas mães e poéticas que abordam maternagens
                diversas no Brasil e na América Latina. Participa também do
                Coletivo Matriz formado por artistas mães do Distrito Federal.
              </h5>
              <div>
                <img src={image_5} alt="" className={"img-fluid"} />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "5vw", marginBottom: 0 }} className={"px-2"}>
            <Col xs={{ span: 12 }} md={{ span: 6, offset: 3 }}>
              <h1
                style={{
                  fontFamily: "Active Regular",
                  fontWeight: "bold",
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Orange,
                  textAlign: "justify",
                }}
              >
                "Casa é essa morada de sossego, de tirar o sutiã e ficar em casa
                tranquila, e deixar as meninas correrem e serem livres nesse
                espaço tão pequeno, mas que é delas, que elas também estão
                criando, esse lugar afetivo de memória e de aconchego".{" "}
              </h1>
            </Col>
          </Row>

          <Row style={{ marginTop: "5vw" }} className={"px-3"}>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Em casa
                </h1>
                <video
                  className={"img-fluid"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_0}
                >
                  <source src={casa_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          <div
            style={{
              backgroundColor: ProjectColors.getInstance().isHighContrast
                ? ProjectColors.getInstance().Black
                : ProjectColors.getInstance().DarkGreen,
              width: "100%",
              marginTop: "5vw",
              paddingTop: "5vw",
            }}
          >
            <Row style={{ marginTop: 10 }} className={"px-2"}>
              <Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }}>
                <Carousel
                  infiniteLoop
                  showIndicators={false}
                  showStatus={false}
                  autoPlay={false}
                >
                  {[
                    <div>
                      <img src={image_3} />
                    </div>,
                    <div>
                      <img src={image_1} />
                    </div>,

                    <div>
                      <img src={image_7} />
                    </div>,

                    <div>
                      <img src={image_4} />
                    </div>,
                    <div>
                      <img src={image_6} />
                    </div>,
                  ]}
                </Carousel>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "5vw", paddingBottom: "5vw" }}
              className={"px-3"}
            >
              <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
                <div>
                  <h1
                    className={"mb-4"}
                    style={{
                      fontFamily: "Active Regular",
                      fontWeight: "bold",
                      color: ProjectColors.getInstance().isHighContrast
                        ? ProjectColors.getInstance().White
                        : ProjectColors.getInstance().Green,
                    }}
                  >
                    Mulher e espaço doméstico
                  </h1>
                  <video
                    className={"img-fluid"}
                    style={{ justifyContent: "center", display: "flex" }}
                    controls
                    poster={frame_1}
                  >
                    <source src={mulher_video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: "5vw" }} className={"px-2"}>
            <Col xs={{ span: 12 }}>
              <div
                className={"justify-content-center"}
                style={{ display: "flex" }}
              >
                <img
                  src={houses}
                  alt=""
                  style={{ height: "max(7vw, 70px)", backgroundColor: "white" }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "5vw" }} className={"px-3"}>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Casa e pandemia
                </h1>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_2}
                >
                  <source src={pandemia_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "5vw" }} className={"px-2"}>
            <Col
              xs={{ span: 12 }}
              md={{ span: 4, offset: 4 }}
              className={"justify-content-center"}
            >
              <div
                style={{ display: "flex" }}
                className={"justify-content-center"}
              >
                <img src={old_house_2} alt="" className={"img-fluid"} />
              </div>
              <div>
                <div style={{ marginTop: 20 }}>
                  <p
                    style={{
                      fontSize: "max(1vw, 15px)",
                      textAlign: "right",
                      color: ProjectColors.getInstance().isHighContrast
                        ? ProjectColors.getInstance().White
                        : ProjectColors.getInstance().Black,
                    }}
                  >
                    Apartamento em Taguatinga Norte onde moro
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row
            style={{ marginTop: "5vw", marginBottom: "5vw" }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Processo artístico
                </h1>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_3}
                >
                  <source src={criacao_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          {/*<Row style={{marginTop: 80}} className={'px-2'}>*/}
          {/*    <Col xs={{span: 12}}>*/}
          {/*        <div>*/}
          {/*            <img src={image_5} alt="" className={'img-fluid'}/>*/}
          {/*        </div>*/}
          {/*    </Col>*/}

          {/*</Row>*/}

          <Row
            style={{
              paddingTop: "5vw",
              paddingBottom: "5vw",
              backgroundColor: ProjectColors.getInstance().isHighContrast
                ? ProjectColors.getInstance().Black
                : ProjectColors.getInstance().DarkGreen,
            }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} md={{ span: 5, offset: 1 }}>
              <h1
                style={{
                  fontFamily: "Active Regular",
                  fontWeight: "bold",
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Green,
                }}
              >
                Conheça os meus trabalhos
              </h1>
              <br />

              <a
                href={
                  "https://drive.google.com/drive/folders/1_ADMTONbaCN3pqHiPXpXeR24BdLwITTD?usp=share_link"
                }
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Meus projetos
              </a>

              <br />
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 5 }}>
              <div>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_5}
                >
                  <source src={arte_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              backgroundImage: "url(" + marina + ")",
              backgroundSize: "cover",
              height: 500,
            }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} className={"d-flex "}>
              <h1
                className={"mb-4"}
                style={{
                  fontFamily: "Gotham Light",
                  marginLeft: 30,
                  fontSize: "70px",
                  color: "white",
                }}
              >
                Julia
                <img
                  src={Arrow}
                  width={50}
                  onClick={() => navigate("/artist/julia")}
                  alt={"Seta laranja apontando para a direita"}
                />
              </h1>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
}
