import MainCustomNavbar from "../../components/MainNavbar";
import image_0 from "../../assets/artist_pictures/renata/picture_0.webp";
import image_1 from "../../assets/artist_pictures/renata/picture_1.webp";
import image_3 from "../../assets/artist_pictures/renata/picture_3.webp";
import image_4 from "../../assets/artist_pictures/renata/picture_2.webp";
import capa_1 from "../../assets/artist_pictures/renata/capa_1.webp";
import capa_2 from "../../assets/artist_pictures/renata/capa_2.webp";
import capa_3 from "../../assets/artist_pictures/renata/capa_3.webp";
import capa_4 from "../../assets/artist_pictures/renata/capa_4.webp";
import fotos from "../../assets/artist_pictures/renata/picture_6.webp";
import mosaico from "../../assets/artist_pictures/renata/picture_4.webp";
import jornal from "../../assets/artist_pictures/renata/picture_5.webp";
// import casa_video from '../../assets/videos/renata/casa.mp4'
// import mulher_video from '../../assets/videos/renata/mulher.mp4'
// import pandemia_video from '../../assets/videos/renata/pandemia.mp4'
// import criacao_video from '../../assets/videos/renata/criacao.mp4'
// import arte_video from '../../assets/videos/renata/performance.mp4'
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
// @ts-ignore
import marina from "../../assets/artist_pictures/tatiane/picture_2.webp";
// @ts-ignore
import ProjectColors from "../../Colors";
import Arrow from "../../assets/components/side_arrow.png";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

import houses from "../../assets/components/houses.png";
import old_house_2 from "../../assets/artist_pictures/renata/old_photo.webp";
import frame_4 from "../../assets/artist_pictures/renata/frame_4.webp";

import VideoPrefix from "../../VideoPrefix";

const casa_video = VideoPrefix + "renata/casa.mp4";
const mulher_video = VideoPrefix + "renata/mulher.mp4";
const pandemia_video = VideoPrefix + "renata/pandemia.mp4";
const criacao_video = VideoPrefix + "renata/criacao.mp4";
const arte_video = VideoPrefix + "renata/performance.mp4";

export default function ProfileRenata() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainCustomNavbar activeTab1={true} />

      <main
        role="main"
        style={{
          backgroundColor: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().Black
            : ProjectColors.getInstance().White,
        }}
      >
        <Container
          fluid
          style={{
            height: "100%",
            padding: 0,
          }}
        >
          <div
            style={{
              background: "url(" + image_0 + ")",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              backgroundAttachment: "fixed",
              backgroundPosition: "75%",
            }}
          >
            <div style={{ height: "20vh", width: "100vw" }}></div>
            <Row
              style={{
                height: "90vh",
                width: "100vw",
                padding: 0,
              }}
            ></Row>
          </div>

          {/*<Row className={'align-items-center px-3'} style={{marginTop: '10vh',}}>*/}

          {/*    <Col xs={{span: 12}} md={{span: 12}}*/}
          {/*        // className={'d-flex align-items-center'}*/}
          {/*    >*/}

          {/*    </Col>*/}
          {/*</Row>*/}

          <Row style={{ marginTop: 10 }} className={"px-2"}>
            <Col xs={{ span: 12 }} md={{ span: 12, offset: 0 }}>
              <h5
                style={{
                  fontFamily: "Gotham",
                  textAlign: "justify",
                  textAlignLast: "justify",
                  color: ProjectColors.getInstance().White,
                  backgroundColor: ProjectColors.getInstance().isHighContrast
                  ? ProjectColors.getInstance().Black
                  : ProjectColors.getInstance().Orange,
                  padding: "20px",
                }}
              >
                Renata Weber (1971) mora e trabalha em Brasília. É escritora,
                cantora, compositora, produtora cultural e artista visual. Atua
                também como psicóloga clínica e pesquisadora em políticas
                públicas em saúde mental (UFRJ). É mestre em antropologia social
                pela Universidade de Brasília e doutora em psiquiatria e saúde
                mental pela Universidade Federal do Rio de Janeiro.
              </h5>
              <div>
                <img src={image_1} alt="" className={"img-fluid"} />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "5vw", marginBottom: 0 }} className={"px-2"}>
            <Col xs={{ span: 12 }} md={{ span: 6, offset: 3 }}>
              <h1
                style={{
                  fontFamily: "Active Regular",
                  fontWeight: "bold",
                  color: ProjectColors.getInstance().isHighContrast
                  ? ProjectColors.getInstance().White
                  : ProjectColors.getInstance().Orange,
                  textAlign: "justify",
                }}
              >
                "Quando eu entro nessa casa, nesse apartamento em especial, eu
                estou o mais livre possível, é um lugar onde eu me sinto com
                menos máscaras, é quando eu estou só na minha casa".{" "}
              </h1>
            </Col>
          </Row>

          <Row style={{ marginTop: "5vw" }} className={"px-3"}>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                  }}
                >
                  Em casa
                </h1>
                <video
                  className={"img-fluid"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={capa_1}
                >
                  <source src={casa_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          <div
            style={{
              backgroundColor: ProjectColors.getInstance().isHighContrast
              ? ProjectColors.getInstance().Black
              : ProjectColors.getInstance().Green,
              width: "100%",
              marginTop: "5vw",
              paddingTop: "5vw",
            }}
          >
            <Row style={{ marginTop: 10 }} className={"px-2"}>
              <Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }}>
                <Carousel
                  infiniteLoop
                  showIndicators={false}
                  showStatus={false}
                  autoPlay={false}
                >
                  {[
                    <div>
                      <img src={mosaico} />
                    </div>,
                    <div>
                      <img src={image_4} />
                    </div>,
                    <div>
                      <img src={jornal} />
                    </div>,

                    <div>
                      <img src={fotos} />
                    </div>,
                    <div>
                      <img src={image_3} />
                    </div>,
                  ]}
                </Carousel>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "5vw", paddingBottom: "5vw" }}
              className={"px-3"}
            >
              <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
                <div>
                  <h1
                    className={"mb-4"}
                    style={{
                      fontFamily: "Active Regular",
                      fontWeight: "bold",
                      color: ProjectColors.getInstance().isHighContrast
                        ? ProjectColors.getInstance().White
                        : ProjectColors.getInstance().DarkGreen,
                    }}
                  >
                    Mulher e espaço doméstico
                  </h1>
                  <video
                    className={"img-fluid"}
                    style={{ justifyContent: "center", display: "flex" }}
                    controls
                    poster={capa_2}
                  >
                    <source src={mulher_video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: "5vw" }} className={"px-2"}>
            <Col xs={{ span: 12 }}>
              <div
                className={"justify-content-center"}
                style={{ display: "flex" }}
              >
                <img
                  src={houses}
                  alt=""
                  style={{ height: "max(7vw, 70px)", backgroundColor: "white" }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "5vw" }} className={"px-3"}>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                  }}
                >
                  Casa e pandemia
                </h1>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={capa_3}
                >
                  <source src={pandemia_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "5vw" }} className={"px-2"}>
            <Col
              xs={{ span: 12 }}
              md={{ span: 6, offset: 3 }}
              className={"justify-content-center"}
            >
              <div
                style={{ display: "flex" }}
                className={"justify-content-center"}
              >
                <img src={old_house_2} alt="" className={"img-fluid"} />
              </div>
              <div>
                <div style={{ marginTop: 20 }}>
                  <p style={{ fontSize: "max(1vw, 15px)", textAlign: "right", color: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().White
            : ProjectColors.getInstance().Black,}}>
                    Apartamento onde nasci na 406 sul
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row
            style={{ marginTop: "5vw", marginBottom: "5vw" }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().DarkGreen,
                  }}
                >
                  Processo artístico
                </h1>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={capa_4}
                >
                  <source src={criacao_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          {/*<Row style={{marginTop: 80}} className={'px-2'}>*/}
          {/*    <Col xs={{span: 12}}>*/}
          {/*        <div>*/}
          {/*            <img src={image_5} alt="" className={'img-fluid'}/>*/}
          {/*        </div>*/}
          {/*    </Col>*/}

          {/*</Row>*/}

          <Row
            style={{
              paddingTop: "5vw",
              paddingBottom: "5vw",
              backgroundColor: ProjectColors.getInstance().isHighContrast
              ? ProjectColors.getInstance().Black
              : ProjectColors.getInstance().Green,
            }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} md={{ span: 5, offset: 1 }}>
              <h1
                style={{
                  fontFamily: "Active Regular",
                  fontWeight: "bold",
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().DarkGreen,
                }}
              >
                Conheça os meus trabalhos
              </h1>
              <br />
              <a
                href="https://www.instagram.com/tata.weber/"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: 28,
                }}
              >
                Instagram
              </a>{" "}
              <br />
              <a
                href="https://drive.google.com/file/d/1frUo16NA0eiVN0Fyi79cCUfKrvI8bEcZ/view"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: 28,
                }}
              >
                Ballet Amaro
              </a>
              <br />
              <a
                href="https://drive.google.com/file/d/1vY6KoLJR6QY0n31bZ4so-cycRtchmhiK/view?usp=share_link"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().DarkGreen,
                  fontSize: 28,
                }}
              >
                O Ano Um
              </a>
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 5 }}>
              <div>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_4}
                >
                  <source src={arte_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              backgroundImage: "url(" + marina + ")",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: 500,
            }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} className={"d-flex "}>
              <h1
                className={"my-4"}
                style={{
                  fontFamily: "Gotham Light",
                  marginLeft: 30,
                  fontSize: "70px",
                  color: "white",
                }}
              >
                Tatiana
                <img
                  src={Arrow}
                  width={50}
                  onClick={() => navigate("/artist/tatiane")}
                  alt={"Seta laranja apontando para a direita"}
                />
              </h1>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
}
