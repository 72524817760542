import { Container } from "react-bootstrap";
import React from "react";
import pjson from "../../package.json";
import ProjectColors from "../Colors";

export default function ProjectVersionFooter() {
  return (
    <Container style={{ margin: 0, padding: 0 }}>
      <div
        className="text-center p-1"
        style={{
          color: ProjectColors.getInstance().White,
          backgroundColor: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().Black
            : ProjectColors.getInstance().Orange,
          width: "100vw",
        }}
      >
        Versão do site: {pjson.version}
      </div>
    </Container>
  );
}
