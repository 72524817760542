import {useNavigate} from "react-router-dom";
import MainCustomNavbar from "../../components/MainNavbar";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import React, {useEffect, useRef, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import Old0 from "../../assets/between_homes/old_photos/old_0.webp";
import Photo0 from "../../assets/mother_homes/casa_antiga.jpg";
import Home0 from '../../assets/components/house_0.png'
import {Icon} from "leaflet";
import ImageMulheres from "../../assets/mother_homes/mulheres.jpg";
import ImageAllBoxes from "../../assets/mother_homes/all_boxes.jpg";


export default function MainPageHouses() {
    const navigate = useNavigate();
    const [zoomValue, setZoomValue] = useState(9);
    const sliderRef = useRef<HTMLDivElement>(null);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        let mouseDown = false;
        let startX: number, scrollLeft: number;
        const slider = sliderRef.current;

        if (!slider) return;

        const startDragging = (e: MouseEvent) => {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };

        const stopDragging = (e: MouseEvent) => {
            mouseDown = false;
        };

        const move = (e: MouseEvent) => {
            e.preventDefault();
            if (!mouseDown) {
                return;
            }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        };

        // Add the event listeners
        slider.addEventListener("mousemove", move, false);
        slider.addEventListener("mousedown", startDragging, false);
        slider.addEventListener("mouseup", stopDragging, false);
        slider.addEventListener("mouseleave", stopDragging, false);
    });

    // Adjust slider width according to available space
    useEffect(() => {
        const slider = sliderRef.current;
        const sidebar = sidebarRef.current;
        const container = containerRef.current;

        if (!slider || !sidebar || !container) return;

        const observerCallback: ResizeObserverCallback = (
            entries: ResizeObserverEntry[]
        ) => {
            window.requestAnimationFrame((): void | undefined => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }
                let sidebarWidth = sidebar.getBoundingClientRect().width;
                let containerWidth = container.getBoundingClientRect().width;
                let sliderWidth = containerWidth - sidebarWidth;
                slider.style.setProperty("width", `${sliderWidth}px`, "important");
                slider.style.width = `${sliderWidth}px !important`;
            });
        };
        const resizeObserver = new ResizeObserver(observerCallback);

        resizeObserver.observe(sidebar);
        resizeObserver.observe(container);

        window.addEventListener("error", (e) => {
            if (
                e.message ===
                "ResizeObserver loop completed with undelivered notifications."
            ) {
                const resizeObserverErrDiv = document.getElementById(
                    "webpack-dev-server-client-overlay-div"
                );
                const resizeObserverErr = document.getElementById(
                    "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute("style", "display: none");
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute("style", "display: none");
                }
            }
        });
    });

    const arrowScroll = (arrowNumber: number) => {
        const slider = sliderRef.current;
        const container = containerRef.current;

        if (!slider || !container) return;

        const scrollWidth = slider.scrollWidth;

        if (arrowNumber == 1) {
            const sliderWidth = slider.getBoundingClientRect().width;
            const containerWidth = container.getBoundingClientRect().width;
            slider.scroll((scrollWidth - sliderWidth) / 2, 0);
        }

        if (arrowNumber == 2) {
            slider.scroll(scrollWidth, 0);
        }
    };

    return (
        <div style={{
            overflow: 'hidden',
            height: '100vh',
            width: '100%'
        }}>
            <MainCustomNavbar activeTab0={false} activeTab1={false} activeTab2={true}/>
            <main role="main">


                <Container className="memory-page-container" ref={containerRef}>
                    <Row xs={2} className="mt-5 g-5 memory-page-row">
                        <Col className="sidebar-col" ref={sidebarRef}>
                            <Sidebar activeTab="casa"/>
                        </Col>
                        <Col className="memory-scrollable-wrapper" ref={sliderRef}>
                            <div className="memory-scrollable-image align-content-center">
                                <img
                                    src={ImageAllBoxes}
                                    alt=""
                                    className={"img-fluid float-start memory-img"}
                                />
                            </div>

                        </Col>

                    </Row>
                </Container>


            </main>
        </div>
    );
};