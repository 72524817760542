import { useNavigate } from "react-router-dom";
import MainCustomNavbar from "../../components/MainNavbar";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import ImageCosturar from "../../assets/mother_homes/costurar.png";
import ImageArrowOrange from "../../assets/components/arrow_orange.png";
import React, { useEffect, useRef } from "react";

import image2 from "../../assets/mother_homes/costurar/2.jpg";
import image3 from "../../assets/mother_homes/costurar/3.jpg";
import image5 from "../../assets/mother_homes/costurar/5.jpg";
import VideoPrefix from "../../VideoPrefix";
import ProjectColors from "../../Colors";

const video0 = VideoPrefix + "casa_materna/costurar.mp4";
export default function Costurar() {
  const navigate = useNavigate();

  const sliderRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let mouseDown = false;
    let startX: number, scrollLeft: number;
    const slider = sliderRef.current;

    if (!slider) return;

    const startDragging = (e: MouseEvent) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const stopDragging = (e: MouseEvent) => {
      mouseDown = false;
    };

    const move = (e: MouseEvent) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    };

    // Add the event listeners
    slider.addEventListener("mousemove", move, false);
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  });

  // Adjust slider width according to available space
  useEffect(() => {
    const slider = sliderRef.current;
    const sidebar = sidebarRef.current;
    const container = containerRef.current;

    if (!slider || !sidebar || !container) return;

    const observerCallback: ResizeObserverCallback = (
      entries: ResizeObserverEntry[]
    ) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        let sidebarWidth = sidebar.getBoundingClientRect().width;
        let containerWidth = container.getBoundingClientRect().width;
        let sliderWidth = containerWidth - sidebarWidth;
        slider.style.setProperty("width", `${sliderWidth}px`, "important");
        slider.style.width = `${sliderWidth}px !important`;
      });
    };
    const resizeObserver = new ResizeObserver(observerCallback);

    resizeObserver.observe(sidebar);
    resizeObserver.observe(container);

    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  });

  const arrowScroll = (arrowNumber: number) => {
    const slider = sliderRef.current;
    const container = containerRef.current;

    if (!slider || !container) return;

    const scrollWidth = slider.scrollWidth;

    if (arrowNumber != 5) {
      const sliderWidth = slider.getBoundingClientRect().width;
      const containerWidth = container.getBoundingClientRect().width;
      console.log(scrollWidth - sliderWidth);
      console.log(scrollWidth);
      console.log(sliderWidth);
      slider.scroll(((scrollWidth + sliderWidth) / 7) * arrowNumber, 0);
    } else {
      slider.scroll(scrollWidth, 0);
    }
  };

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100%",
        backgroundColor: ProjectColors.getInstance().isHighContrast
          ? ProjectColors.getInstance().Black
          : ProjectColors.getInstance().VeryLightBlue,
      }}
    >
      <MainCustomNavbar activeTab0={false} activeTab2={true} />
      <main role="main">
        <Container className="memory-page-container" ref={containerRef}>
          <Row xs={2} className="mt-5 g-5 memory-page-row">
            <Col className="sidebar-col" ref={sidebarRef}>
              <Sidebar activeTab="costurar" />
            </Col>
            <Col
              className="memory-scrollable-wrapper"
              ref={sliderRef}
              style={{
                backgroundColor: ProjectColors.getInstance().isHighContrast
                  ? ProjectColors.getInstance().DarkGreen
                  : ProjectColors.getInstance().White,
              }}
            >
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={ImageCosturar}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5">
                <button onClick={() => arrowScroll(1)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image2}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5">
                <button onClick={() => arrowScroll(2)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image3}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5">
                <button onClick={() => arrowScroll(3)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div
                className="memory-scrollable-text"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Black,
                }}
              >
                <p>
                  “Eu acho que a resistência numa casa é manter a sua
                  personalidade nela, deixar ela com a sua cara, independente do
                  tempo e de quem está morando lá” (Lucila).
                </p>
              </div>
              <div className="memory-scrollable-arrow mx-5">
                <button onClick={() => arrowScroll(4)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image5}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5">
                <button onClick={() => arrowScroll(5)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image  align-content-center">
                <video className={"img-fluid "} controls>
                  <source src={video0} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
