import {useNavigate} from "react-router-dom";
import MainCustomNavbar from "../../components/MainNavbar";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import VideoPrefix from "../../VideoPrefix";
import Arrow from "../../assets/components/side_arrow.png";
import React from "react";
import ProjectColors from "../../Colors";

const BackgroundVideo = VideoPrefix + "background_video_house.mp4";

export default function IndexPageMemory() {
    const navigate = useNavigate();

    return (
        <div
            style={{
                overflow: "hidden",
                height: "100%",
                width: "100%",
                position: "absolute",
            }}
        >
            <MainCustomNavbar
                activeTab0={false}
                activeTab1={false}
                activeTab2={true}
            />
            <main role="main">
                <Container>
                    <video
                        id="background-video-between-homes"
                        autoPlay
                        loop
                        muted
                        className={"background-video"}
                        style={{
                            backgroundColor: ProjectColors.getInstance().isHighContrast
                                ? ProjectColors.getInstance().Black
                                : ProjectColors.getInstance().White,
                        }}
                    >
                        <source src={BackgroundVideo} type="video/mp4"/>
                    </video>
                    <Row className={"align-items-center"} style={{height: "90vh"}}>
                        <Col
                            xs={{span: 12, offset: 0}}
                            sm={{span: 10, offset: 1}}
                            lg={{span: 10, offset: 1}}
                            xll={{span: 8, offset: 2}}
                            style={{textAlign: "justify"}}
                        >
                            {/*<h1 style={{color: 'white', textShadow: ' 0 0 1px #000'}}>Casa de artista</h1>*/}
                            <span
                                style={{
                                    color: "white",
                                    textAlign: "justify",
                                    textShadow: " 0 0 5px #000",
                                    fontSize: "min(25px, 4vw )",
                                }}
                            >
                <span style={{fontWeight: "bold"}}> Casa-memória </span>
                traz a casa em seu sentido mais tradicional: morada
                interpenetrada pela ideia de fixidez, habitação localizada e
                enraizada no solo, espaço de acolhimento, lugar onde repousa o
                que nos é íntimo e conhecido. Aqui, habitar nos sugere uma
                associação costumeira: a “casa” como sinônimo de residência
                familiar associado à noção de permanência e pertencimento. A
                investigação desse modo de habitar apoiou-se na casa do Lago
                Norte, residência construída em Brasília pela minha mãe na
                cidade de Brasília nos anos oitenta. Na época, divorciada e
                sozinha com quatro filhos, decidiu erguer sua morada em um
                bairro afastado da cidade e pouco habitado. Tomei-a como uma
                “casa-resistência” visto que sua construção foi edificada em um
                ato desbravador de uma mulher destemida que não se intimidou em
                adquirir um terreno em um local a esmo (na época), com
                pouquíssimas casas vizinhas, sem luz ou asfalto na rua, para
                construir ali sua morada.
              </span>

                            <br/>
                            <br/>

                            <img
                                src={Arrow}
                                style={{width: "min(100px, 10vw)"}}
                                onClick={() => navigate("/memory/houses")}
                                alt={"Seta laranja apontando para a direita"}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
        </div>
    );
}
