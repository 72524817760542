import MainCustomNavbar from "../../components/MainNavbar";
import image_0 from "../../assets/artist_pictures/caisa/picture_0.webp";
import image_1 from "../../assets/artist_pictures/caisa/picture_1.webp";
import carrousel_1 from "../../assets/artist_pictures/caisa/picture_3.webp";
import carrousel_3 from "../../assets/artist_pictures/caisa/picture_5.webp";
import carrousel_0 from "../../assets/artist_pictures/caisa/picture_4.webp";
import carrousel_2 from "../../assets/artist_pictures/caisa/picture_2.webp";
// import casa_video from '../../assets/videos/caisa/casa.mp4'
// import mulher_video from '../../assets/videos/caisa/mulher.mp4'
// import pandemia_video from '../../assets/videos/caisa/pandemia.mp4'
// import criacao_video from '../../assets/videos/caisa/criacao.mp4'
// import arte_video from '../../assets/videos/caisa/performance.mp4'
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
// @ts-ignore
import marina from "../../assets/artist_pictures/leticia/picture_0.webp";
// @ts-ignore
import ProjectColors from "../../Colors";
import Arrow from "../../assets/components/side_arrow.png";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import frame_0 from "../../assets/artist_pictures/caisa/frame_0.webp";
import frame_1 from "../../assets/artist_pictures/caisa/frame_1.webp";
import frame_2 from "../../assets/artist_pictures/caisa/frame_2.webp";
import frame_3 from "../../assets/artist_pictures/caisa/frame_3.webp";
import carrousel_4 from "../../assets/artist_pictures/caisa/picture_6.webp";
import houses from "../../assets/components/houses.png";
import old_house from "../../assets/artist_pictures/caisa/old_house.webp";
import frame_4 from "../../assets/artist_pictures/caisa/frame_4.webp";

import VideoPrefix from "../../VideoPrefix";

const casa_video = VideoPrefix + "caisa/casa.mp4";
const mulher_video = VideoPrefix + "caisa/mulher.mp4";
const pandemia_video = VideoPrefix + "caisa/pandemia.mp4";
const criacao_video = VideoPrefix + "caisa/criacao.mp4";
const arte_video = VideoPrefix + "caisa/performance.mp4";

export default function ProfileCaisa() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainCustomNavbar activeTab1={true} />

      <main
        role="main"
        style={{
          backgroundColor: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().Black
            : ProjectColors.getInstance().White,
        }}
      >
        <Container
          fluid
          style={{
            height: "100%",
            padding: 0,
          }}
        >
          {/*<Row*/}
          {/*    className={'align-items-center px-3'} style={{height: '100vh', width: '100vw'}}>*/}

          {/*    <Col xs={{span: 12}} md={{span: 12}}>*/}

          {/*        <h1 style={{fontFamily: 'Active Regular', fontWeight: 'bold', textAlign: 'center'}}>Ana Luíza</h1>*/}

          {/*    </Col>*/}
          {/*</Row>*/}

          <div
            style={{
              background: "url(" + image_0 + ")",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              backgroundAttachment: "fixed",
              backgroundPosition: "75%",
            }}
          >
            <div style={{ height: "20vh", width: "100vw" }}></div>
            <Row
              style={{
                height: "90vh",
                width: "100vw",
                padding: 0,
              }}
            ></Row>
          </div>

          {/*<Row className={'align-items-center px-3'} style={{marginTop: '10vh',}}>*/}

          {/*    <Col xs={{span: 12}} md={{span: 12}}*/}
          {/*        // className={'d-flex align-items-center'}*/}
          {/*    >*/}

          {/*    </Col>*/}
          {/*</Row>*/}

          <Row style={{ marginTop: 10 }} className={"px-2"}>
            <Col xs={{ span: 12 }} md={{ span: 12, offset: 0 }}>
              <h5
                style={{
                  fontFamily: "Gotham",
                  textAlign: "justify",
                  textAlignLast: "justify",
                  color: ProjectColors.getInstance().White,
                  backgroundColor: ProjectColors.getInstance().isHighContrast
                  ? ProjectColors.getInstance().Black
                  : ProjectColors.getInstance().Orange,
                  padding: "20px",
                }}
              >
                Caísa Tibúrcio é mãe, atriz, palhaça, musicista, diretora,
                pesquisadora e produtora. É bacharel, mestre e doutoranda em
                artes cênicas pela Universidade de Brasília. É amante do teatro
                e atua a 25 anos como artista da cena. Tem um núcleo de
                trabalhos solos chamado "Casulo Teatro" por meio do qual realiza
                suas montagens, pesquisas cênicas musicais e circulações no
                Brasil e no exterior. Atua com os espetáculos: "Enluarada: uma
                epopeia sertaneja", com o infantil "Sementes" e com o seu mais
                recente trabalho "Abre Alas: concerto à doidivana". Também é
                integrante do Coletivo Criadouros, núcleo de trabalho com a qual
                realizou pesquisa e montagem do espetáculo "Achadouros – teatro
                para bebês" e do Coletivo Entre Vazios que pesquisa poéticas
                urbanas e formas animadas com a performance urbana “Lourença”.
              </h5>
              <div>
                <img src={image_1} alt="" className={"img-fluid"} />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "5vw", marginBottom: 0 }} className={"px-2"}>
            <Col xs={{ span: 12 }} md={{ span: 6, offset: 3 }}>
              <h1
                style={{
                  fontFamily: "Active Regular",
                  fontWeight: "bold",
                  color: ProjectColors.getInstance().isHighContrast
                  ? ProjectColors.getInstance().White
                  : ProjectColors.getInstance().Orange,
                  textAlign: "justify",
                }}
              >
                "Eu acho essa palavra casa tão maravilhosa, ela abarca muitos
                significados, não só a sua moradia, mas seu recanto, seu ninho,
                seu porto seguro, o lugar onde você pode se fortalecer, se
                recolher, é o lugar de descanso, de criação também".{" "}
              </h1>
            </Col>
          </Row>

          <Row style={{ marginTop: "5vw" }} className={"px-3"}>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Em casa
                </h1>
                <video
                  className={"img-fluid"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_0}
                >
                  <source src={casa_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          <div
            style={{
              backgroundColor: ProjectColors.getInstance().isHighContrast
                ? ProjectColors.getInstance().Black
                : ProjectColors.getInstance().DarkGreen,
              width: "100%",
              marginTop: "5vw",
              paddingTop: "5vw",
            }}
          >
            <Row style={{ marginTop: 10 }} className={"px-2"}>
              <Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }}>
                <Carousel
                  infiniteLoop
                  showIndicators={false}
                  showStatus={false}
                  autoPlay={false}
                >
                  {[
                    <div>
                      <img src={carrousel_0} />
                    </div>,
                    <div>
                      <img src={carrousel_1} />
                    </div>,

                    <div>
                      <img src={carrousel_2} />
                    </div>,

                    <div>
                      <img src={carrousel_3} />
                    </div>,
                    <div>
                      <img src={carrousel_4} />
                    </div>,
                  ]}
                </Carousel>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "5vw", paddingBottom: "5vw" }}
              className={"px-3"}
            >
              <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
                <div>
                  <h1
                    className={"mb-4"}
                    style={{
                      fontFamily: "Active Regular",
                      fontWeight: "bold",
                      color: ProjectColors.getInstance().isHighContrast
                        ? ProjectColors.getInstance().White
                        : ProjectColors.getInstance().Green,
                    }}
                  >
                    Mulher e espaço doméstico
                  </h1>
                  <video
                    className={"img-fluid"}
                    style={{ justifyContent: "center", display: "flex" }}
                    controls
                    poster={frame_1}
                  >
                    <source src={mulher_video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: "5vw" }} className={"px-2"}>
            <Col xs={{ span: 12 }}>
              <div
                className={"justify-content-center"}
                style={{ display: "flex" }}
              >
                <img
                  src={houses}
                  alt=""
                  style={{ height: "max(7vw, 70px)", backgroundColor: "white" }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "5vw" }} className={"px-3"}>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Casa e pandemia
                </h1>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_2}
                >
                  <source src={pandemia_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "5vw" }} className={"px-2"}>
            <Col
              xs={{ span: 12 }}
              md={{ span: 6, offset: 3 }}
              className={"justify-content-center"}
            >
              <div
                style={{ display: "flex" }}
                className={"justify-content-center"}
              >
                <img src={old_house} alt="" className={"img-fluid"} />
              </div>
              <div>
                <div style={{ marginTop: 20 }}>
                  <p style={{ fontSize: "max(1vw, 15px)", textAlign: "right", color: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().White
            : ProjectColors.getInstance().Black, }}>
                    Eu nasci aqui
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row
            style={{ marginTop: "5vw", marginBottom: "5vw" }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Processo artístico
                </h1>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_3}
                >
                  <source src={criacao_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          {/*<Row style={{marginTop: 80}} className={'px-2'}>*/}
          {/*    <Col xs={{span: 12}}>*/}
          {/*        <div>*/}
          {/*            <img src={image_5} alt="" className={'img-fluid'}/>*/}
          {/*        </div>*/}
          {/*    </Col>*/}

          {/*</Row>*/}

          <Row
            style={{
              paddingTop: "5vw",
              paddingBottom: "5vw",
              backgroundColor: ProjectColors.getInstance().isHighContrast
                ? ProjectColors.getInstance().Black
                : ProjectColors.getInstance().DarkGreen,
            }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} md={{ span: 5, offset: 1 }}>
              <h1
                style={{
                  fontFamily: "Active Regular",
                  fontWeight: "bold",
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Green,
                }}
              >
                Conheça os meus trabalhos
              </h1>
              <br />

              <a
                href="https://www.casuloteatro.com/"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Casulo Teatro
              </a>
              <br />

              <a
                href="https://www.criadouros.com/"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Criadouros
              </a>
              <br />

              <a
                href="https://youtu.be/OqkGjdLz16k"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Espetáculo Abre Alas - Concerto à doidivana
              </a>
              <br />

              <a
                href="https://youtu.be/xgrHLBmuKBg"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Espetáculo Enluarada - Uma epopeia sertaneja
              </a>

              <br />

              <a
                href="https://youtu.be/yybSqwgvBE0"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Espetáculo Sementes
              </a>

              <br />

              <a
                href="https://www.youtube.com/watch?v=8puhvNUq0wU"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Espetáculo Achadouros - Teatro para bebês
              </a>

              <br />

              <a
                href="https://www.youtube.com/watch?time_continue=4&v=N7VvOwzAF7c"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Espetáculo Cria
              </a>

              <br />
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 5 }}>
              <div>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_4}
                >
                  <source src={arte_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              backgroundImage: "url(" + marina + ")",
              backgroundSize: "cover",
              height: 500,
            }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} className={"d-flex "}>
              <h1
                className={"my-4"}
                style={{
                  fontFamily: "Gotham Light",
                  marginLeft: 30,
                  fontSize: "70px",
                  color: "white",
                }}
              >
                Leticia
                <img
                  src={Arrow}
                  width={50}
                  onClick={() => navigate("/artist/leticia")}
                  alt={"Seta laranja apontando para a direita"}
                />
              </h1>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
}
