import {useNavigate} from "react-router-dom";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from 'react-leaflet'
import {Container} from "react-bootstrap";
import MainCustomNavbar from "../../components/MainNavbar";
import React, {useState} from "react";
import {Icon} from "leaflet";
import Home0 from '../../assets/components/house_0.png'
import Home1 from '../../assets/components/house_1.png'
import Home2 from '../../assets/components/house_2.png'
import Home3 from '../../assets/components/house_3.png'
import Home4 from '../../assets/components/house_4.png'
import Sound from '../../assets/components/sound_icon.png'
import Letter from '../../assets/components/letter.png'
import Photos from '../../assets/components/photos.png'

import Old0 from '../../assets/between_homes/old_photos/old_0.webp'
import Old1 from '../../assets/between_homes/old_photos/old_1.webp'
import Old2 from '../../assets/between_homes/old_photos/old_2.webp'
import Old3 from '../../assets/between_homes/old_photos/old_3.webp'
import Old4 from '../../assets/between_homes/old_photos/old_4.webp'


import Sound0 from '../../assets/between_homes/sounds/house_0/Rue de La Liberation.mp3'
import Sound1 from '../../assets/between_homes/sounds/house_0/Gare Rambouillet Place Fernand Prud_homme.mp3'
import Sound2 from '../../assets/between_homes/sounds/house_1/Ponto de ônibus chaussée de la muette.mp3'
import Sound3 from '../../assets/between_homes/sounds/house_1/Parque infantil Fontaine des Bois Avenida Prudhon.mp3'
import Sound4 from '../../assets/between_homes/sounds/house_1/1 Rue Octave Feuillet.mp3'
import Sound5 from '../../assets/between_homes/sounds/house_2/dentro do metro .mp3'
import Sound6 from '../../assets/between_homes/sounds/house_2/Iris Cafe 86,  rue picpus.mp3'
import Sound7 from '../../assets/between_homes/sounds/house_2/Estacao de metro Daumesnil.mp3'
import Sound8 from '../../assets/between_homes/sounds/house_3/18 rue Cascades Librairie.mp3'
import Sound9 from '../../assets/between_homes/sounds/house_3/área lazer 88 rue menilmontant .mp3'
import Sound10 from '../../assets/between_homes/sounds/house_3/Ménil_Lav lavanderia automatica 92 rue Menilmontant.mp3'
import Sound11 from '../../assets/between_homes/sounds/house_4/Igreja Notre Dame de la Gare 2 Place Jeanne d Arc.mp3'
import Sound12 from '../../assets/between_homes/sounds/house_4/supermercado 14 rue Reims.mp3'
import ProjectColors from "../../Colors";

export default function MapView() {
    const navigate = useNavigate();
    const [zoomValue, setZoomValue] = useState(9);


    const SetZoomLocation = () => {
        const mapObject = useMap()


        useMapEvents({
            zoomend() { // zoom event (when zoom animation ended)
                const zoom = mapObject.getZoom(); // get current Zoom of map
                setZoomValue(zoom);
                console.log(zoomValue)

            },


        });


        return null;
    }

    const Home0Icon = new Icon({
        iconUrl: Home0,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [45, 55],
    })

    const Home1Icon = new Icon({
        iconUrl: Home1,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [45, 55],
    })
    const Home2Icon = new Icon({
        iconUrl: Home2,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [45, 55],
    })
    const Home3Icon = new Icon({
        iconUrl: Home3,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [45, 55],
    })
    const Home4Icon = new Icon({
        iconUrl: Home4,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [45, 55],
    })

    const SoundIcon = new Icon({
        iconUrl: Sound,
        iconAnchor: [5, 55],
        popupAnchor: [10, -44],
        iconSize: [25, 25],
    })

    const LocationFinderDummy = () => {
        useMapEvents({
            click(e) {
                console.log(e.latlng);
            },
        });
        return null;
    };


    return (
        <div

            style={{
                height: '100vh',
                overflow: 'hidden',

            }}
        >
            <MainCustomNavbar activeTab3={true}/>
            <main role="main">
                {/* Header para descrição da página para leitor de tela */}
                <h1 style={{height: 0, margin: 0, color: 'rgba(0,0,0,0)', userSelect: 'none'}}>
                    Essa página apresenta um mapa interativo de Paris com indicadores clicáveis
                    que marcam o endereço de cada casa em que morei nos anos noventa.
                    Os marcadores são enumerados de um a cinco e, quando clicados, apresentam uma
                    caixa com uma foto da casa, seu nome e dois botões. O primeiro botão leva para
                    mais fotos da casa, enquanot o segundo leva para as fotos das cartas que escrevi.
                </h1>
                <Container fluid style={{padding: 0}}>
                    <MapContainer center={[48.74078354166723, 2.1244812011718754]} zoom={10}
                                  touchZoom={true}
                                  doubleClickZoom={true}

                                  zoomControl={false}
                                  scrollWheelZoom={true}


                                  style={{
                                      height: '93vh',
                                      width: '100vw',
                                      overflow: 'hidden'

                                  }}>

                        <SetZoomLocation/>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'

                            // url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png'
                            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
                            // url="https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png"
                        />

                        {/*<MapEvents/>*/}

                        <LocationFinderDummy/>
                        <Marker position={[48.4990417, 1.7152297]} icon={Home0Icon}>
                            <Popup minWidth={250} closeButton={false} className={'no-padding'}>
                                <img src={Old0} alt="" width={250} className={'m-auto'}/>
                                <br/>
                                <br/>
                                <div style={{width: 250}} className={'align-content-center'}>
                                    <p style={{
                                        color: ProjectColors.getInstance().Black,
                                        textAlign: 'center',
                                        textAlignLast: 'center',
                                        fontSize: '1.25rem',
                                        margin: 0,
                                    }}>Atravesso soleiras</p>
                                </div>
                                <div className={'align-content-end'}>
                                    <img src={Photos} alt="" width={30} className={'m-1'}
                                         onClick={() => navigate('/house/house0/photos')}/>
                                    <img src={Letter} alt="" onClick={() => navigate('/house/house0/letter')} width={30}
                                         className={'m-1'}/>
                                </div>
                            </Popup>
                        </Marker>

                        <Marker position={[48.8605157, 2.2735928]} icon={Home1Icon}>
                            <Popup minWidth={250} closeButton={false}>
                                <img src={Old1} alt="" width={250} className={'m-auto'}/>
                                <br/>
                                <br/>
                                <div style={{width: 250}} className={'align-content-center'}>
                                    <p style={{
                                        color: ProjectColors.getInstance().Black,
                                        textAlign: 'center',
                                        textAlignLast: 'center',
                                        fontSize: '1.25rem',
                                        margin: 0,
                                    }}>Enfim, moro só.</p>
                                </div>
                                <div className={'align-content-end'}>
                                    <img src={Photos} alt="" width={30} className={'m-1'}
                                         onClick={() => navigate('/house/house1/photos')}/>
                                    <img src={Letter} alt="" onClick={() => navigate('/house/house1/letter')} width={30}
                                         className={'m-1'}/>
                                </div>
                            </Popup>
                        </Marker>


                        <Marker position={[48.8385752, 2.4006909]} icon={Home2Icon}>
                            <Popup minWidth={250} closeButton={false}>
                                <img src={Old2} alt="" width={250} className={'m-auto'}/>
                                <br/>
                                <br/>
                                <div style={{width: 250}} className={'align-content-center'}>
                                    <p style={{
                                        color: ProjectColors.getInstance().Black,
                                        textAlign: 'center',
                                        textAlignLast: 'center',
                                        fontSize: '1.25rem',
                                        margin: 0,
                                    }}>Tudo aqui é provisório.</p>
                                </div>
                                <div className={'align-content-end'}>
                                    <img src={Photos} alt="" width={30} className={'m-1'}
                                         onClick={() => navigate('/house/house2/photos')}/>
                                    <img src={Letter} alt="" onClick={() => navigate('/house/house2/letter')} width={30}
                                         className={'m-1'}/>
                                </div>
                            </Popup>
                        </Marker>

                        <Marker position={[48.8713743, 2.3910351]} icon={Home3Icon}>
                            <Popup minWidth={250} closeButton={false}>
                                <img src={Old3} alt="" width={250} className={'m-auto'}/>
                                <br/>
                                <br/>
                                <div style={{width: 250}} className={'align-content-center'}>
                                    <p style={{
                                        color: ProjectColors.getInstance().Black,
                                        textAlign: 'center',
                                        textAlignLast: 'center',
                                        fontSize: '1.25rem',
                                        margin: 0,
                                    }}>Tenho endereço fixo. Até quando?</p>
                                </div>
                                <div className={'align-content-end'}>
                                    <img src={Photos} alt="" width={30} className={'m-1'}
                                         onClick={() => navigate('/house/house3/photos')}/>
                                    <img src={Letter} alt="" onClick={() => navigate('/house/house3/letter')} width={30}
                                         className={'m-1'}/>
                                </div>
                            </Popup>
                        </Marker>


                        <Marker position={[48.8265895, 2.3726319]} icon={Home4Icon}>
                            <Popup minWidth={250} closeButton={false}>
                                <img src={Old4} alt="" width={250} className={'m-auto'}/>
                                <br/>
                                <br/>
                                <div style={{width: 250}} className={'align-content-center'}>
                                    <p style={{
                                        color: ProjectColors.getInstance().Black,
                                        textAlign: 'center',
                                        textAlignLast: 'center',
                                        fontSize: '1.25rem',
                                        margin: 0,
                                    }}>Eu não me sinto em casa.</p>
                                </div>
                                <div className={'align-content-end'}>
                                    <img src={Photos} alt="" width={30} className={'m-1'}
                                         onClick={() => navigate('/house/house4/photos')}/>
                                    <img src={Letter} alt="" onClick={() => navigate('/house/house4/letter')} width={30}
                                         className={'m-1'}/>
                                </div>
                            </Popup>
                        </Marker>


                        {zoomValue >= 13 ?

                            <Marker position={[48.498723781336304, 1.7137576808929446]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound0} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}


                        {zoomValue >= 1 ?
                            <Marker position={[48.6433947, 1.8308278]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound1} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}
                        {zoomValue >= 13 ?

                            <Marker position={[48.8581344, 2.27205]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound2} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}

                        {zoomValue >= 13 ?

                            <Marker position={[48.8584576, 2.2693959]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound3} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}
                        {zoomValue >= 13 ?

                            <Marker position={[48.8602244, 2.273589]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound4} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}

                        {zoomValue >= 13 ?
                            <Marker position={[48.8699501, 2.3785216]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound5} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}

                        {zoomValue >= 13 ?
                            <Marker position={[48.8394281, 2.4005133]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound6} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}

                        {zoomValue >= 13 ?
                            <Marker position={[48.8386281, 2.3959133]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound7} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}
                        {zoomValue >= 13 ?
                            <Marker position={[48.8698132, 2.3908549]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound8} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}

                        {zoomValue >= 13 ?
                            <Marker position={[48.8689329, 2.3913204]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound9} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}

                        {zoomValue >= 13 ?
                            <Marker position={[48.8676621, 2.3859196]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound10} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}
                        {zoomValue >= 13 ?
                            <Marker position={[48.8297916, 2.3680848]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound11} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}

                        {zoomValue >= 13 ?
                            <Marker position={[48.8282951, 2.3709945]} icon={SoundIcon}>
                                <Popup minWidth={250} closeButton={false}>
                                    <audio controls>

                                        <source src={Sound12} type="audio/mpeg"/>
                                    </audio>
                                </Popup>

                            </Marker> : null}


                    </MapContainer>


                </Container>
            </main>
        </div>

    )

}