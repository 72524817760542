import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MainCustomNavbar from "../../components/MainNavbar";
import Arrow from "../../assets/components/side_arrow.png";
// @ts-ignore
// import BackgroundVideo from '../../assets/videos/background_video.mp4'

import VideoPrefix from "../../VideoPrefix";
import ProjectColors from "../../Colors";
const BackgroundVideo = VideoPrefix + "background_video.mp4";

export default function IndexPageArtist() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100%",
        width: "100%",
        position: "absolute",
      }}
    >
      <MainCustomNavbar activeTab0={false} activeTab1={true} />
      <main role="main">
        <Container>
          <video
            id="background-video"
            autoPlay
            loop
            muted
            className={"background-video"}
            style={{
              backgroundColor: ProjectColors.getInstance().isHighContrast
                ? ProjectColors.getInstance().Black
                : ProjectColors.getInstance().White,
            }}
          >
            <source src={BackgroundVideo} type="video/mp4" />
          </video>
          <Row className={"align-items-center"} style={{ height: "90vh" }}>
            <Col
              xs={{ span: 12, offset: 0 }}
              sm={{ span: 10, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              xll={{ span: 8, offset: 2 }}
              style={{ textAlign: "justify" }}
            >
              {/*<h1 style={{color: 'white', textShadow: ' 0 0 1px #000'}}>Casa de artista</h1>*/}
              <span
                style={{
                  color: "white",
                  textAlign: "justify",
                  textShadow: " 0 0 5px #000",
                  fontSize: "min(25px, 4vw )",
                }}
              >
                <span style={{ fontWeight: "bold" }}>Casa de artista</span>{" "}
                propõe uma leitura sensível do espaço doméstico a partir das
                vivências de mulheres artistas frente às problemáticas
                cotidianas, agravadas pela chegada da pandemia de Covid-19.
                Durante esse período, diversas profissões foram extremamente
                penalizadas, entre elas, as profissionais do campo das artes. Em
                minha prática artística, busquei perceber a casa como lugar
                propulsor de processos artísticos autobiográficos, mas também
                como refúgio para as artistas reclusas, afetadas pela pandemia,
                suas incertezas e consequente desamparo.
              </span>
              <br />
              <br />

              <img
                src={Arrow}
                style={{ width: "min(100px, 10vw)" }}
                onClick={() => navigate("/artist/select")}
                alt={"Seta laranja apontando para a direita"}
              />
            </Col>

            {/*<Col xs={{span: 10, offset: 1}} sm={{span: 8, offset: 4}}>*/}

            {/*    <Button className={'align-middle'} variant={'outline-primary'}><h1>A casa de Minha Mãe</h1></Button>*/}
            {/*    <br/>*/}
            {/*    <br/>*/}
            {/*    <br/>*/}
            {/*    <Button className={'align-middle'} variant={'outline-primary'} onClick={() => redirectToProfile()}>*/}
            {/*        <h1>Casa de*/}
            {/*            Artista</h1>*/}
            {/*    </Button>*/}

            {/*</Col>*/}
            {/*<div onClick={() => navigate('/artist/select')}*/}
            {/*     onScroll={() => console.log('scroll')}*/}
            {/*     style={{position: 'absolute', bottom: 0, left: '50%', color: '#FFFFFF'}}>*/}
            {/*    /!*<h5 className={'align-content-center'} style={{marginLeft: -25}}>Iniciar</h5>*!/*/}
            {/*    <img src={arrow} alt="" style={{*/}
            {/*        width: '100px',*/}
            {/*        height: '100px',*/}
            {/*        opacity: 0.5,*/}
            {/*        marginLeft: -50,*/}

            {/*    }}*/}
            {/*         className={'white-filter'}/>*/}
            {/*</div>*/}
          </Row>
        </Container>
      </main>
    </div>
  );
}
