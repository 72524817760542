import {Container, Nav, Navbar} from "react-bootstrap";
import React, {CSSProperties, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ProjectColors from "../Colors";
import Brand from "../assets/logo_files/logo_3.png";
import HighContrast from "../assets/components/contrast.png";

export type MainNavProps = {
    activeTab0?: boolean
    activeTab1?: boolean
    activeTab2?: boolean
    activeTab3?: boolean
    activeTab4?: boolean
}

export default function MainCustomNavbar(props: MainNavProps) {
    const navigate = useNavigate();
    const navbarStyle: CSSProperties = {
        borderRadius: 10,
        position: "absolute",
        marginTop: "-20px",
        boxShadow: "3px 5px 3px #000000",
        width: "6vw",
        minWidth: "60px",
        backgroundColor: ProjectColors.getInstance().White,
    };

    return (
        <Navbar
            expand="lg"
            style={{
                marginRight: -9,
                marginLeft: -9,
                padding: 5,
                height: 60,
                backgroundColor: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().Black
                    : ProjectColors.getInstance().Orange,
            }}
            sticky={"top"}
            className={"fixed-top"}
        >
            <Container fluid className={""}>
                <Navbar.Brand
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <img src={Brand}
                         alt="Logo do projeto casa reexistência, contem duas casas brancas com o telhado laranja em cima de uma mão, abaixo delas existe o texto 'Casas de Reexistência' "
                         style={navbarStyle}/>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto mt-2 special-font">
                        <Navbar.Brand
                            onClick={() => {
                                ProjectColors.getInstance().toggleHighContrast(navigate);
                            }}
                            style={{color: ProjectColors.getInstance().White}}
                        >
                            <img src={HighContrast} alt="Ícone para ligar e desligar o modo alto contraste"
                                 height={20}/>
                        </Navbar.Brand>

                        <Nav.Link
                            onClick={() => {
                                navigate("/");
                            }}
                            active={props.activeTab0 === true}
                            style={{
                                fontWeight: props.activeTab0 ? "bold" : "",
                                color: ProjectColors.getInstance().White,
                            }}
                        >
                            <span>INICIO</span>
                        </Nav.Link>

                        <Nav.Link
                            onClick={() => {
                                navigate("/artist");
                            }}
                            active={props.activeTab1 === true}
                            style={{
                                fontWeight: props.activeTab1 ? "bold" : "",
                                color: ProjectColors.getInstance().White,
                            }}
                        >
                            <span>CASA DE ARTISTA</span>
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => navigate("/memory")}
                            active={props.activeTab2 === true}
                            style={{
                                fontWeight: props.activeTab2 ? "bold" : "",
                                color: ProjectColors.getInstance().White,
                            }}
                        >
                            <span>CASA-MEMÓRIA</span>
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => {
                                navigate("/house");
                            }}
                            active={props.activeTab3 === true}
                            style={{
                                fontWeight: props.activeTab3 ? "bold" : "",
                                color: ProjectColors.getInstance().White,
                            }}
                        >
                            <span>ENTRE-CASAS</span>
                        </Nav.Link>
                        <Nav.Link
                            onClick={() => {
                                navigate("/about");
                            }}
                            active={props.activeTab4 === true}
                            style={{
                                fontWeight: props.activeTab4 ? "bold" : "",
                                color: ProjectColors.getInstance().White,
                            }}
                        >
                            <span>SOBRE</span>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
