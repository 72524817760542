import MainCustomNavbar from "../components/MainNavbar";
import {Card, Col, Container, Row} from "react-bootstrap";
import React from "react";
import background0 from "../assets/background/teia.jpg";
import {TypeAnimation} from "react-type-animation";
import {useNavigate} from "react-router-dom";
import Fac from "../assets/logo_files/fac.png";
import Sec from "../assets/logo_files/sec.png";
import ProjectColors from "../Colors";
import WhiteArrow from "../assets/components/white_arrow.png";
import LogoUnb from "../assets/logo_files/fac_unb_2.png";
// import teaser_0 from "../assets/videos/teaser_0.mp4";
// import teaser_2 from "../assets/videos/teaser_between_homes.mp4";
import teaser_0_placeholder from "../assets/main/teaser_0_poster.webp";
import teaser_1_placeholder from "../assets/main/teaser_1_poster.webp";
import Con from "../assets/logo_files/connection.png";

import VideoPrefix from "../VideoPrefix";

const teaser_0 = VideoPrefix + "teaser_0.mp4";
const teaser_1 = VideoPrefix + "outros/teaser_1.mp4";
const teaser_2 = VideoPrefix + "teaser_between_homes.mp4";

export default function IndexPage() {
    const navigate = useNavigate();
    return (
        <div
            style={{
                backgroundColor: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().Black
                    : ProjectColors.getInstance().Green,
            }}
            className={"pb-5"}
        >
            <MainCustomNavbar activeTab0={true} activeTab1={false}/>
            <main
                role="main"
                style={{
                    backgroundColor: ProjectColors.getInstance().isHighContrast
                        ? ProjectColors.getInstance().Black
                        : ProjectColors.getInstance().Green,
                }}
            >
                <Container
                    fluid
                    style={{
                        height: "100%",
                        backgroundImage: "url(" + background0 + ")",
                        backgroundSize: "cover",
                        backgroundColor: ProjectColors.getInstance().isHighContrast
                            ? ProjectColors.getInstance().Black
                            : ProjectColors.getInstance().Green,
                    }}
                    title={
                        "Imagem de fundo contendo uma parede branca com uma pequena teia de aranha enroscada em um barbante branco na vertical"
                    }
                    className={"mh-100"}
                >
                    {/*<video id="background-video" autoPlay loop muted poster="https://assets.codepen.io/6093409/river.jpg"*/}
                    {/*       className={"background-video"}>*/}
                    {/*    <source src="https://assets.codepen.io/6093409/river.mp4" type="video/mp4"/>*/}
                    {/*</video>*/}

                    <Row className={"align-items-center vh-100"}>
                        <Col
                            xs={{span: 10, offset: 1}}
                            sm={{span: 10, offset: 1}}
                            className={"mt-5"}
                            style={{paddingTop: "min(11vw, 300px)"}}
                        >
                            <TypeAnimation
                                sequence={[
                                    "",
                                    2000,
                                    "Cartografia da casa-resistência",
                                    1000,
                                    "Resistência",
                                    1000,
                                    "Reexistência",
                                    500,
                                    "Casas de (re)existência",
                                    () => {
                                        console.log("Done typing!"); // Place optional callbacks anywhere in the array
                                    },
                                ]}
                                wrapper="div"
                                cursor={false}
                                style={{
                                    fontSize: "min(9vw, 105px)",
                                    color: "#ffffff",
                                    fontFamily: "Active Regular",
                                }}
                            />
                        </Col>

                        {/*<Col xs={{span: 10, offset: 1}} sm={{span: 8, offset: 4}}>*/}

                        {/*    <Button className={'align-middle'} variant={'outline-primary'}><h1>A casa de Minha Mãe</h1></Button>*/}
                        {/*    <br/>*/}
                        {/*    <br/>*/}
                        {/*    <br/>*/}
                        {/*    <Button className={'align-middle'} variant={'outline-primary'} onClick={() => redirectToProfile()}>*/}
                        {/*        <h1>Casa de*/}
                        {/*            Artista</h1>*/}
                        {/*    </Button>*/}

                        {/*</Col>*/}
                    </Row>
                </Container>
                <Container
                    fluid
                    className="index-page-container"
                    style={{
                        backgroundColor: ProjectColors.getInstance().isHighContrast
                            ? ProjectColors.getInstance().Black
                            : ProjectColors.getInstance().Green,
                    }}
                >
                    {/*<video id="background-video" autoPlay loop muted poster="https://assets.codepen.io/6093409/river.jpg"*/}
                    {/*       className={"background-video"}>*/}
                    {/*    <source src="https://assets.codepen.io/6093409/river.mp4" type="video/mp4"/>*/}
                    {/*</video>*/}

                    <Row
                        className={"align-items-center mt-5 g-5 pb-5 pb-md-0"}
                        style={{
                            height: "100vh",
                            backgroundColor: ProjectColors.getInstance().isHighContrast
                                ? ProjectColors.getInstance().Black
                                : ProjectColors.getInstance().Green,
                        }}
                    >
                        <Col
                            xs={{span: 12}}
                            sm={{span: 10, offset: 1}}
                            className={"pb-5 pb-md-0"}
                            style={{
                                backgroundColor: ProjectColors.getInstance().isHighContrast
                                    ? ProjectColors.getInstance().Black
                                    : ProjectColors.getInstance().Green,
                            }}
                        >
                            <h1
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().White
                                        : ProjectColors.getInstance().DarkGreen,
                                    fontWeight: "bold",
                                }}
                                className={"mt-5 mt-sm-0"}
                            >
                                Casas de (re)existência
                            </h1>
                            <br/>
                            <p
                                style={{
                                    color: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().White
                                        : ProjectColors.getInstance().DarkGreen,
                                    textAlign: "justify",
                                    textAlignLast: "justify",
                                    fontSize: "1.5rem",
                                    lineHeight: 1.1,
                                }}
                            >
                                Durante minha pesquisa “Cartografia subjetiva
                                da casa-resistência: espaço doméstico e narrativas femininas” em
                                Artes Visuais (PPGFAV/UFG), instituí abordagens artísticas
                                autobiográficas para investigar uma poética da casa, habitando
                                esse lugar enquanto mulher, artista e pesquisadora. De todas as
                                casas que morei, procurei reconhecer e identificar aquelas que
                                me levaram à ideia de “casa-resistência” a partir da etimologia
                                da palavra “resistere” que significa “ficar firme”,
                                “perseverar”. O termo “resistência” me conduziu à palavra
                                “(re)existência” enquanto sinônimo de “emergir”, “renascer”, no
                                sentido de retomar as casas de um outro modo, revisitando-as
                                criativamente, reinventando-as enquanto materialidade poética
                                para erguer modos subjetivos de habitar o espaço doméstico.
                                <br/>
                                <br/>O web-documentário{" "}
                                <span style={{fontWeight: "bold"}}>
                  Casas de (re)existência
                </span>{" "}
                                surge como fruto dessa pesquisa, com o objetivo de reunir
                                narrativas de mulheres atravessadas por memórias afetivas de
                                suas moradas. Sua elaboração teve como base ensaios visuais e
                                sonoros, registros audiovisuais, escritos, caixas de guardados,
                                mapas, desenhos, entre outros, no intuito de evocar o que pode a
                                casa enquanto dimensão subjetiva e autobiográfica. Assim,
                                amparado pela concepção da casa como lugar de “resistência” e de
                                “reexistência” para as mulheres, o presente webdoc se apresenta
                                estruturado em torno de três temáticas distintas (Casa de
                                artista, Casa-memória e Entre-casas), propondo perspectivas
                                diversas para se perceber casas, seja cotidianamente seja
                                poeticamente.
                            </p>
                        </Col>
                        <Col
                            xs={{span: 12}}
                            sm={{span: 12, offset: 0}}
                            lg={{span: 10, offset: 1}}
                            style={{
                                backgroundColor: ProjectColors.getInstance().isHighContrast
                                    ? ProjectColors.getInstance().Black
                                    : ProjectColors.getInstance().Green,
                            }}
                        >
                            <Row
                                xs={1}
                                md={3}
                                className="g-4 mb-2 "
                                style={{
                                    backgroundColor: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().Black
                                        : ProjectColors.getInstance().Green,
                                }}
                            >
                                <Col>
                                    <Card
                                        style={{
                                            borderRadius: 0,
                                            backgroundColor: ProjectColors.getInstance().Orange,
                                        }}
                                    >
                                        <video
                                            className={"img-fluid m-auto"}
                                            style={{justifyContent: "center", display: "flex"}}
                                            poster={teaser_0_placeholder}
                                            controls
                                        >
                                            <source src={teaser_0} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                        <Card.Body
                                            style={{
                                                backgroundColor: ProjectColors.getInstance().Orange,
                                            }}
                                            className={"super-hover"}
                                            onClick={() => {
                                                navigate("/artist");
                                            }}
                                        >
                                            <Card.Title
                                                style={{color: ProjectColors.getInstance().White}}
                                            >
                                                Casa de artista{" "}
                                                <img
                                                    src={WhiteArrow}
                                                    width={10}
                                                    alt={"Seta laranja apontando para a direita"}
                                                />
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card
                                        style={{
                                            borderRadius: 0,
                                            backgroundColor: ProjectColors.getInstance().Orange,
                                        }}
                                    >
                                        <video
                                            className={"img-fluid m-auto"}
                                            style={{justifyContent: "center", display: "flex"}}

                                            controls
                                        >
                                            <source src={teaser_1} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>

                                        <Card.Body
                                            style={{
                                                backgroundColor: ProjectColors.getInstance().Orange,
                                            }}
                                            className={"super-hover"}
                                            onClick={() => {
                                                navigate("/memory");
                                            }}
                                        >


                                            <Card.Title
                                                style={{color: ProjectColors.getInstance().White}}
                                            >
                                                Casa-memória{" "}
                                                <img
                                                    src={WhiteArrow}
                                                    width={10}
                                                    alt={"Seta laranja apontando para a direita"}
                                                />
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card
                                        style={{
                                            borderRadius: 0,
                                            backgroundColor: ProjectColors.getInstance().Orange,
                                        }}
                                    >
                                        <video
                                            className={"img-fluid m-auto"}
                                            style={{justifyContent: "center", display: "flex"}}
                                            poster={teaser_1_placeholder}
                                            controls
                                        >
                                            <source src={teaser_2} type="video/mp4"/>
                                            Your browser does not support the video tag.
                                        </video>
                                        <Card.Body
                                            style={{
                                                backgroundColor: ProjectColors.getInstance().Orange,
                                            }}
                                            className={"super-hover"}
                                            onClick={() => {
                                                navigate("/artist");
                                            }}
                                        >
                                            <Card.Title
                                                style={{color: ProjectColors.getInstance().White}}
                                            >
                                                Entre-casas{" "}
                                                <img
                                                    src={WhiteArrow}
                                                    width={10}
                                                    alt={"Seta laranja apontando para a direita"}
                                                />
                                            </Card.Title>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row
                                className="g-4  mt-3 pb-5"
                                style={{
                                    backgroundColor: ProjectColors.getInstance().isHighContrast
                                        ? ProjectColors.getInstance().Black
                                        : ProjectColors.getInstance().Green,
                                }}
                            >
                                <Col xs={{span: 12}} sm={{span: 10, offset: 1}}>
                                    <p
                                        style={{
                                            color: ProjectColors.getInstance().isHighContrast
                                                ? ProjectColors.getInstance().White
                                                : ProjectColors.getInstance().DarkGreen,
                                            fontSize: "1.5rem",
                                            lineHeight: 1.1,
                                        }}
                                    >
                                        Este projeto foi realizado com o apoio do Programa Conexão
                                        Cultura DF e com recursos do Fundo de Apoio à Cultura do DF.
                                    </p>
                                    <br/>
                                    <br/>
                                    <Row
                                        xs={2}
                                        md={4}
                                        className={"g-4"}
                                        style={{
                                            backgroundColor: ProjectColors.getInstance()
                                                .isHighContrast
                                                ? ProjectColors.getInstance().White
                                                : ProjectColors.getInstance().Green,
                                        }}
                                    >
                                        <Col>
                                            <img
                                                src={Fac}
                                                alt="Logo do Fundo de Apoio à Cultura do Distrito Federal"
                                                className={"img-fluid  logo float-start"}
                                                style={{paddingTop: 10}}
                                            />
                                        </Col>

                                        <Col>
                                            <img
                                                src={Con}
                                                alt="Logo da Secretaria de Cultura e Economia Criativa do Distrito Federal"
                                                className={"img-fluid  mx-auto d-block   logo"}
                                                width={140}
                                            />
                                        </Col>
                                        <Col>
                                            <img
                                                src={LogoUnb}
                                                className={"img-fluid logo float-md-end float-start"}
                                                alt="Logo Faculdade de Comunicação da Universidade de Brasília"
                                                style={{paddingTop: 10}}
                                            />
                                        </Col>
                                        <Col>
                                            <img
                                                src={Sec}
                                                alt="Logo da Secretaria de Cultura e Economia Criativa do Distrito Federal"
                                                className={"img-fluid  mx-auto d-block   logo"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {/*<Button variant="outline-dark" className={'button-custom mb-4 mb-sm-0'} size='lg'*/}
                            {/*        style={{width: '200px', marginRight: '2vw'}}*/}
                            {/*        onClick={() => navigate('/artist')}>Entre-casas</Button>*/}
                            {/*<Button variant="outline-dark" className={'button-custom'} size='lg' style={{width: '280px'}}>Casa*/}
                            {/*    de minha mãe</Button>*/}
                        </Col>

                        {/*<Col xs={{span: 10, offset: 1}} sm={{span: 8, offset: 4}}>*/}

                        {/*    <Button className={'align-middle'} variant={'outline-primary'}><h1>A casa de Minha Mãe</h1></Button>*/}
                        {/*    <br/>*/}
                        {/*    <br/>*/}
                        {/*    <br/>*/}
                        {/*    <Button className={'align-middle'} variant={'outline-primary'} onClick={() => redirectToProfile()}>*/}
                        {/*        <h1>Casa de*/}
                        {/*            Artista</h1>*/}
                        {/*    </Button>*/}

                        {/*</Col>*/}
                    </Row>
                </Container>

                {/* <div style={{
                height: '110vh', 
                backgroundColor: ProjectColors.getInstance().Green
            }}/> */}
            </main>
        </div>
    );
}
