import MainCustomNavbar from "../../../components/MainNavbar";
import { Container } from "react-bootstrap";
import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ModalImage from "react-modal-image";
import image_0 from "../../../assets/between_homes/new_photos/house_4/13 1.JPG";
import image_1 from "../../../assets/between_homes/new_photos/house_4/13 2.JPG";
import image_2 from "../../../assets/between_homes/new_photos/house_4/13 3.JPG";
import image_3 from "../../../assets/between_homes/new_photos/house_4/13 4.JPG";
import ProjectColors from "../../../Colors";
import image_4 from "../../../assets/between_homes/new_photos/house_4/13 5.JPG";
import image_5 from "../../../assets/between_homes/new_photos/house_4/13 6.JPG";
import image_6 from "../../../assets/between_homes/new_photos/house_4/13 7.JPG";
import image_7 from "../../../assets/between_homes/new_photos/house_4/13 8.JPG";
import image_8 from "../../../assets/between_homes/new_photos/house_4/A.JPG";
import { useNavigate } from "react-router-dom";

export default function PhotosHouse4() {
  const navigate = useNavigate();
  return (
    <>
      <MainCustomNavbar activeTab3={true} />
      <main
        role="main"
        style={{
          backgroundColor: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().Black
            : ProjectColors.getInstance().White,
        }}
      >
        {/* Header para descrição da página para leitor de tela */}
        <h1
          style={{
            height: 0,
            margin: 0,
            color: "rgba(0,0,0,0)",
            userSelect: "none",
          }}
        >
          Essa página apresenta as nove fotos da casa "Eu não me sinto em casa".
        </h1>
        <Container
          fluid
          style={{
            backgroundColor: ProjectColors.getInstance().isHighContrast
              ? ProjectColors.getInstance().Black
              : ProjectColors.getInstance().Green,
          }}
        >
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            style={{ paddingTop: 20, paddingBottom: 20 }}
          >
            <Masonry gutter={"5px"}>
              <ModalImage small={image_0} large={image_0} alt="" />
              <ModalImage small={image_1} large={image_1} alt="" />
              <ModalImage small={image_2} large={image_2} alt="" />
              <ModalImage small={image_3} large={image_3} alt="" />
              <ModalImage small={image_4} large={image_4} alt="" />
              <ModalImage small={image_5} large={image_5} alt="" />
              <ModalImage small={image_6} large={image_6} alt="" />
              <ModalImage small={image_7} large={image_7} alt="" />
              <ModalImage small={image_8} large={image_8} alt="" />
            </Masonry>
          </ResponsiveMasonry>
        </Container>
      </main>
    </>
  );
}
