import { useNavigate } from "react-router-dom";
import MainCustomNavbar from "../../components/MainNavbar";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import ImageLibertar from "../../assets/mother_homes/gestar.png";
import ImageArrowOrange from "../../assets/components/arrow_orange.png";
import React, { useEffect, useRef } from "react";
import image2 from "../../assets/mother_homes/gestar/2.jpg";
import image4a from "../../assets/mother_homes/gestar/4a.jpg";
import image4b from "../../assets/mother_homes/gestar/4b.jpg";
import image5 from "../../assets/mother_homes/gestar/5.jpg";
import image6a from "../../assets/mother_homes/gestar/6a.jpg";
import image6b from "../../assets/mother_homes/gestar/6b.jpg";
import image7 from "../../assets/mother_homes/gestar/7.jpg";
import image8a from "../../assets/mother_homes/gestar/8a.jpg";
import image8b from "../../assets/mother_homes/gestar/8b.jpg";
import image9 from "../../assets/mother_homes/gestar/9.jpg";
import VideoPrefix from "../../VideoPrefix";
import ProjectColors from "../../Colors";

const video0 = VideoPrefix + "casa_materna/gestar.mp4";

export default function Gestar() {
  const navigate = useNavigate();

  const sliderRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let mouseDown = false;
    let startX: number, scrollLeft: number;
    const slider = sliderRef.current;

    if (!slider) return;

    const startDragging = (e: MouseEvent) => {
      mouseDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    };

    const stopDragging = (e: MouseEvent) => {
      mouseDown = false;
    };

    const move = (e: MouseEvent) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - slider.offsetLeft;
      const scroll = x - startX;
      slider.scrollLeft = scrollLeft - scroll;
    };

    // Add the event listeners
    slider.addEventListener("mousemove", move, false);
    slider.addEventListener("mousedown", startDragging, false);
    slider.addEventListener("mouseup", stopDragging, false);
    slider.addEventListener("mouseleave", stopDragging, false);
  });

  // Adjust slider width according to available space
  useEffect(() => {
    const slider = sliderRef.current;
    const sidebar = sidebarRef.current;
    const container = containerRef.current;

    if (!slider || !sidebar || !container) return;

    const observerCallback: ResizeObserverCallback = (
      entries: ResizeObserverEntry[]
    ) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        let sidebarWidth = sidebar.getBoundingClientRect().width;
        let containerWidth = container.getBoundingClientRect().width;
        let sliderWidth = containerWidth - sidebarWidth;
        slider.style.setProperty("width", `${sliderWidth}px`, "important");
        slider.style.width = `${sliderWidth}px !important`;
      });
    };
    const resizeObserver = new ResizeObserver(observerCallback);

    resizeObserver.observe(sidebar);
    resizeObserver.observe(container);

    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  });

  const arrowScroll = (arrowNumber: number) => {
    const slider = sliderRef.current;
    const container = containerRef.current;

    if (!slider || !container) return;

    const scrollWidth = slider.scrollWidth;

    if (arrowNumber !== 9) {
      const sliderWidth = slider.getBoundingClientRect().width;
      const containerWidth = container.getBoundingClientRect().width;

      slider.scroll(((scrollWidth + sliderWidth) / 11) * arrowNumber, 0);
    } else {
      slider.scroll(scrollWidth, 0);
    }
  };

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100%",
        backgroundColor: ProjectColors.getInstance().isHighContrast
          ? ProjectColors.getInstance().Black
          : ProjectColors.getInstance().VeryLightBlue,
      }}
    >
      <MainCustomNavbar activeTab0={false} activeTab2={true} />
      <main role="main">
        <Container className="memory-page-container" ref={containerRef}>
          <Row xs={2} className="mt-5 g-5 memory-page-row">
            <Col className="sidebar-col" ref={sidebarRef}>
              <Sidebar activeTab="gestar" />
            </Col>
            <Col
              className="memory-scrollable-wrapper"
              ref={sliderRef}
              style={{
                backgroundColor: ProjectColors.getInstance().isHighContrast
                  ? ProjectColors.getInstance().DarkGreen
                  : ProjectColors.getInstance().White,
              }}
            >
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={ImageLibertar}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(1)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image2}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(2)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>

              <div
                className="memory-scrollable-text"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Black,
                }}
              >
                <p>
                  “Hoje eu associo casa com refúgio e faço uma ligação com
                  útero, ventre, ninho, com um lugar que a gente pode chamar de
                  seu” (Madalena).
                </p>
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(3)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image4a}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
                <img
                  src={image4b}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(4)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image5}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(5)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image6a}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
                <img
                  src={image6b}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(6)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image7}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(7)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image8a}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
                <img
                  src={image8b}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(8)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={image9}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                />
              </div>
              <div className="memory-scrollable-arrow mx-5 mx-2">
                <button onClick={() => arrowScroll(9)}>
                  <img
                    src={ImageArrowOrange}
                    alt=""
                    className={"img-fluid float-start memory-arrow"}
                  />
                </button>
              </div>
              <div className="memory-scrollable-image memory-scrollable-image align-content-center">
                <video className={"img-fluid "} controls>
                  <source src={video0} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
