import {useNavigate} from "react-router-dom";
import MainCustomNavbar from "../../components/MainNavbar";
import {Col, Container, Row} from "react-bootstrap";
import Arrow from "../../assets/components/side_arrow.png";
import React from "react";

import VideoPrefix from "../../VideoPrefix";
import ProjectColors from "../../Colors";

const BackgroundVideo = VideoPrefix + "background_video_foot.mp4";

export default function IndexPageHouses() {
    const navigate = useNavigate();

    return (
        <div
            style={{
                overflow: "hidden",
                height: "100%",
                width: "100%",
                position: "absolute",
            }}
        >
            <MainCustomNavbar activeTab3={true}/>
            <main role="main">
                <Container>
                    <video
                        id="background-video-between-homes"
                        autoPlay
                        loop
                        muted
                        className={"background-video"}
                        style={{
                            backgroundColor: ProjectColors.getInstance().isHighContrast
                                ? ProjectColors.getInstance().Black
                                : ProjectColors.getInstance().White,
                        }}
                    >
                        <source src={BackgroundVideo} type="video/mp4"/>
                    </video>
                    <Row className={"align-items-center"} style={{height: "90vh"}}>
                        <Col
                            xs={{span: 12, offset: 0}}
                            sm={{span: 10, offset: 1}}
                            lg={{span: 10, offset: 1}}
                            xll={{span: 8, offset: 2}}
                            style={{textAlign: "justify"}}
                        >
                            {/*<h1 style={{color: 'white', textShadow: ' 0 0 1px #000'}}>Casa de artista</h1>*/}
                            <span
                                style={{
                                    color: "white",
                                    textAlign: "justify",
                                    textShadow: " 0 0 5px #000",
                                    fontSize: "min(25px, 4vw )",
                                }}
                            >
                <span style={{fontWeight: "bold"}}> Entre-casas </span>
               foi concebido a partir de experiências pessoais de moradia em Paris, nos anos noventa e, em contraponto à ideia da casa como um lugar permanente e fixo. Naquela época, residindo como estudante estrangeira, morei em diferentes domicílios (sozinha e com uma amiga), o que me levou a reconhecer neste modo “desenraizado” de se habitar a cidade, um ato de resistência. A abordagem artística proposta se inicia com meu retorno, em 2022, a cinco casas de meu passado na cidade (enquanto ainda me recuperava de uma fratura no pé esquerdo). Voltar a essas casas como artista teve como intuito traçar uma cartografia subjetiva e poética de experiências transitórias nos espaços domésticos e de sua estética gestual cotidiana.
              </span>
                            <br/>
                            <br/>

                            <img
                                src={Arrow}
                                style={{width: "min(100px, 10vw)"}}
                                onClick={() => navigate("/house/map")}
                                alt={"Seta laranja apontando para a direita"}
                            />
                        </Col>
                    </Row>
                </Container>
            </main>
        </div>
    );
}
