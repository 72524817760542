import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import MainCustomNavbar from "../../components/MainNavbar";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageAbrigar from "../../assets/mother_homes/abrigar.png";
import ImageArrowOrange from "../../assets/components/arrow_orange.png";
import ImageMulheres from "../../assets/mother_homes/mulheres.jpg";
import { Carousel } from "react-responsive-carousel";
import video from "*.mp4";
import VideoPrefix from "../../VideoPrefix";
import debora from "../../assets/mother_homes/frames/debora.webp";
import gabi from "../../assets/mother_homes/frames/gabi.webp";
import joana from "../../assets/mother_homes/frames/joana.webp";
import maria from "../../assets/mother_homes/frames/maria.webp";
import ProjectColors from "../../Colors";

const video0 = VideoPrefix + "casa_materna/amar.mp4";
const mariaVideo = VideoPrefix + "casa_materna_entrevista/maria.mp4";
const gabiVideo = VideoPrefix + "casa_materna_entrevista/gabi.mp4";
const joanaVideo = VideoPrefix + "casa_materna_entrevista/joana.mp4";
const deboraVideo = VideoPrefix + "casa_materna_entrevista/debora.mp4";
export default function Women() {
  const navigate = useNavigate();

  const customRenderThumb = (children: any[]) =>
    children.map((item, index) => {
      if (index === 0) {
        return <img src={ImageMulheres} />;
      }
      if (index === 1) {
        return <img src={debora} />;
      }

      if (index === 2) {
        return <img src={gabi} />;
      }
      if (index === 3) {
        return <img src={joana} />;
      }
      if (index === 4) {
        return <img src={maria} />;
      }

      return <img src={ImageMulheres} />;
    });

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100%",
        backgroundColor: ProjectColors.getInstance().isHighContrast
          ? ProjectColors.getInstance().Black
          : ProjectColors.getInstance().VeryLightBlue,
      }}
    >
      <MainCustomNavbar activeTab0={false} activeTab2={true} />
      <main role="main">
        <Container className="memory-page-container">
          <Row className="mt-5 g-5 ">
            <Col xs={2} className="sidebar-col">
              <Sidebar activeTab="mulheres" />
            </Col>
            <Col xs={8} className="">
              <div
                className="justify-content-center align-content-center  "
                style={{ height: "80vh" }}
              >
                <Carousel
                  axis={"horizontal"}
                  dynamicHeight={true}
                  renderThumbs={customRenderThumb}
                >
                  <div>
                    <img src={ImageMulheres} alt="" className={" "} />
                  </div>
                  <div>
                    <video className={"img-fluid"} controls>
                      <source src={deboraVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div>
                    <video className={"img-fluid"} controls>
                      <source src={gabiVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div>
                    <video className={"img-fluid"} controls>
                      <source src={joanaVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div>
                    <video className={"img-fluid"} controls>
                      <source src={mariaVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
