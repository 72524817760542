import MainCustomNavbar from "../../../components/MainNavbar";
import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import ProjectColors from "../../../Colors";
import image_0 from "../../../assets/between_homes/letters/house_0/gue 1.jpg";
import image_1 from "../../../assets/between_homes/letters/house_0/gue 2.jpg";
import image_2 from "../../../assets/between_homes/letters/house_0/gue 3.jpg";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";

export default function LetterHouse0() {
  const navigate = useNavigate();
  return (
    <>
      <MainCustomNavbar activeTab3={true} />
      <main
        role="main"
        style={{
          backgroundColor: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().Black
            : ProjectColors.getInstance().White,
        }}
      >
        {/* Header para descrição da página para leitor de tela */}
        <h1
          style={{
            height: 0,
            margin: 0,
            color: "rgba(0,0,0,0)",
            userSelect: "none",
          }}
        >
          Essa página apresenta as três fotos das cartas de "Atravesso
          soleiras".
        </h1>
        <Container
          fluid
          style={{
            backgroundColor: ProjectColors.getInstance().isHighContrast
              ? ProjectColors.getInstance().Black
              : ProjectColors.getInstance().Green,
            minHeight: "100vh",
          }}
        >
          <Row style={{ paddingTop: 20 }}>
            <Col xs={{ span: 12 }} sm={{ span: 4, offset: 4 }}>
              <Carousel
                infiniteLoop
                showIndicators={false}
                showStatus={false}
                autoPlay={false}
              >
                {[
                  <div>
                    <img src={image_0} alt="" />
                  </div>,
                  <div>
                    <img src={image_1} alt="" />
                  </div>,
                  <div>
                    <img src={image_2} alt="" />
                  </div>,
                ]}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
}
