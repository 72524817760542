import MainCustomNavbar from "../../components/MainNavbar";
import image_0 from "../../assets/artist_pictures/leticia/picture_0.webp";
import image_5 from "../../assets/artist_pictures/leticia/picture_5.webp";
import picture_7 from "../../assets/artist_pictures/leticia/picture_7.webp";
// import casa_video from '../../assets/videos/leticia/casa.mp4'
// import mulher_video from '../../assets/videos/leticia/mulher.mp4'
// import pandemia_video from '../../assets/videos/leticia/pandemia.mp4'
// import criacao_video from '../../assets/videos/leticia/criacao.mp4'
// import arte_video from '../../assets/videos/leticia/performance_1.mp4'
import { Col, Container, Row } from "react-bootstrap";
import React, { useEffect } from "react";
// @ts-ignore
import marina from "../../assets/artist_pictures/ana/picture_0.webp";
// @ts-ignore
import ProjectColors from "../../Colors";
import Arrow from "../../assets/components/side_arrow.png";
import { useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import frame_0 from "../../assets/artist_pictures/leticia/leticia_frame_0.webp";
import frame_1 from "../../assets/artist_pictures/leticia/leticia_frame_1.webp";
import houses from "../../assets/components/houses.png";
import frame_2 from "../../assets/artist_pictures/leticia/leticia_frame_3.webp";
import old_house_2 from "../../assets/artist_pictures/leticia/picture_6.webp";
import frame_3 from "../../assets/artist_pictures/leticia/leticia_frame_4.webp";
import carrousel_1 from "../../assets/artist_pictures/leticia/carrossel_1.webp";
import carrousel_2 from "../../assets/artist_pictures/leticia/carrossel_2.webp";
import carrousel_3 from "../../assets/artist_pictures/leticia/carrossel_3.webp";
import carrousel_4 from "../../assets/artist_pictures/leticia/carrossel_4.webp";
import carrousel_5 from "../../assets/artist_pictures/leticia/carrossel_5.webp";

import VideoPrefix from "../../VideoPrefix";

const casa_video = VideoPrefix + "leticia/casa.mp4";
const mulher_video = VideoPrefix + "leticia/mulher.mp4";
const pandemia_video = VideoPrefix + "leticia/pandemia.mp4";
const criacao_video = VideoPrefix + "leticia/criacao.mp4";
const arte_video = VideoPrefix + "leticia/performance.mp4";

export default function ProfileLeticia() {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainCustomNavbar activeTab1={true} />

      <main
        role="main"
        style={{
          backgroundColor: ProjectColors.getInstance().isHighContrast
            ? ProjectColors.getInstance().Black
            : ProjectColors.getInstance().White,
        }}
      >
        <Container
          fluid
          style={{
            height: "100%",
            padding: 0,
          }}
        >
          {/*<Row*/}
          {/*    className={'align-items-center px-3'} style={{height: '100vh', width: '100vw'}}>*/}

          {/*    <Col xs={{span: 12}} md={{span: 12}}>*/}

          {/*        <h1 style={{fontFamily: 'Active Regular', fontWeight: 'bold', textAlign: 'center'}}>Ana Luíza</h1>*/}

          {/*    </Col>*/}
          {/*</Row>*/}

          <div
            style={{
              background: "url(" + image_0 + ")",
              backgroundSize: "cover",
              height: "100%",
              width: "100%",
              backgroundAttachment: "fixed",
              backgroundPosition: "75%",
            }}
          >
            <div style={{ height: "20vh", width: "100vw" }}></div>
            <Row
              style={{
                height: "90vh",
                width: "100vw",
                padding: 0,
              }}
            ></Row>
          </div>

          {/*<Row className={'align-items-center px-3'} style={{marginTop: '10vh',}}>*/}

          {/*    <Col xs={{span: 12}} md={{span: 12}}*/}
          {/*        // className={'d-flex align-items-center'}*/}
          {/*    >*/}

          {/*    </Col>*/}
          {/*</Row>*/}

          <Row style={{ marginTop: 10 }} className={"px-2"}>
            <Col xs={{ span: 12 }} md={{ span: 12, offset: 0 }}>
              <h5
                style={{
                  fontFamily: "Gotham",
                  textAlign: "justify",
                  textAlignLast: "justify",
                  color: ProjectColors.getInstance().White,
                  backgroundColor: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().Black
                    : ProjectColors.getInstance().Orange,
                  padding: "20px",
                }}
              >
                Letícia Miranda (1996) é poeta, artista visual e professora.
                Vive e trabalha em Brasília. É cofundadora e integrante do
                “Clube de Colagem de Brasília” (CCBSB). É formada em Letras
                Português pela Universidade de Brasília (2017), especialista em
                Fotografia pela Faculdade Unyleya e Espaço f/508 de Cultura
                (2019) e mestra em Artes Visuais (2022) pela UnB. Seus trabalhos
                relacionam poéticas e linguagens (como poesia, colagem e
                fotografia) a fim de encontrar rastros e traços de um mundo
                possível. Em 2022 lançou seu primeiro livro de poemas intitulado
                "A suspensão de Tomie Ohtake", pela Editora Penalux.
              </h5>
              <div>
                <img src={image_5} alt="" className={"img-fluid"} />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "5vw", marginBottom: 0 }} className={"px-2"}>
            <Col xs={{ span: 12 }} md={{ span: 6, offset: 3 }}>
              <h1
                style={{
                  fontFamily: "Active Regular",
                  fontWeight: "bold",
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Orange,
                  textAlign: "justify",
                }}
              >
                "Acho que a casa é um lugar em que a gente existe sendo a gente
                mesmo, em que há cem por cento de acolhimento, que a gente não
                precisa de tantos filtros e tantas máscaras".{" "}
              </h1>
            </Col>
          </Row>

          <Row style={{ marginTop: "5vw" }} className={"px-3"}>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Em casa
                </h1>
                <video
                  className={"img-fluid"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_0}
                >
                  <source src={casa_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          <div
            style={{
              backgroundColor: ProjectColors.getInstance().isHighContrast
                ? ProjectColors.getInstance().Black
                : ProjectColors.getInstance().Brown,
              width: "100%",
              marginTop: "5vw",
              paddingTop: "5vw",
            }}
          >
            <Row style={{ marginTop: 10 }} className={"px-2"}>
              <Col xs={{ span: 12 }} sm={{ span: 10, offset: 1 }}>
                <Carousel
                  infiniteLoop
                  showIndicators={false}
                  showStatus={false}
                  autoPlay={false}
                >
                  {[
                    <div>
                      <img src={carrousel_1} />
                    </div>,
                    <div>
                      <img src={carrousel_2} />
                    </div>,
                    <div>
                      <img src={carrousel_3} />
                    </div>,
                    <div>
                      <img src={carrousel_4} />
                    </div>,
                    <div>
                      <img src={carrousel_5} />
                    </div>,
                  ]}
                </Carousel>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "5vw", paddingBottom: "5vw" }}
              className={"px-3"}
            >
              <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
                <div>
                  <h1
                    className={"mb-4"}
                    style={{
                      fontFamily: "Active Regular",
                      fontWeight: "bold",
                      color: ProjectColors.getInstance().isHighContrast
                        ? ProjectColors.getInstance().White
                        : ProjectColors.getInstance().Green,
                    }}
                  >
                    Mulher e espaço doméstico
                  </h1>
                  <video
                    className={"img-fluid"}
                    style={{ justifyContent: "center", display: "flex" }}
                    controls
                    poster={frame_1}
                  >
                    <source src={mulher_video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: "5vw" }} className={"px-2"}>
            <Col xs={{ span: 12 }}>
              <div
                className={"justify-content-center"}
                style={{ display: "flex" }}
              >
                <img
                  src={houses}
                  alt=""
                  style={{ height: "max(7vw, 70px)", backgroundColor: "white" }}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: "5vw" }} className={"px-3"}>
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Casa e pandemia
                </h1>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_2}
                >
                  <source src={pandemia_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "5vw" }} className={"px-2"}>
            <Col
              xs={{ span: 12 }}
              md={{ span: 6, offset: 3 }}
              className={"justify-content-center"}
            >
              <div
                style={{ display: "flex" }}
                className={"justify-content-center"}
              >
                <img src={old_house_2} alt="" className={"img-fluid"} />
              </div>
              <div>
                <div style={{ marginTop: 20 }}>
                  <p
                    style={{
                      fontSize: "max(1vw, 15px)",
                      textAlign: "right",
                      color: ProjectColors.getInstance().isHighContrast
                        ? ProjectColors.getInstance().White
                        : ProjectColors.getInstance().Black,
                    }}
                  >
                    Casa da minha avó paterna em Ibotirama - Bahia
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row
            style={{ marginTop: "5vw", marginBottom: "5vw" }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} md={{ span: 10, offset: 1 }}>
              <div>
                <h1
                  className={"mb-4"}
                  style={{
                    fontFamily: "Active Regular",
                    fontWeight: "bold",
                    color: ProjectColors.getInstance().isHighContrast
                      ? ProjectColors.getInstance().White
                      : ProjectColors.getInstance().Green,
                  }}
                >
                  Processo artístico
                </h1>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={frame_3}
                >
                  <source src={criacao_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          {/*<Row style={{marginTop: 80}} className={'px-2'}>*/}
          {/*    <Col xs={{span: 12}}>*/}
          {/*        <div>*/}
          {/*            <img src={image_5} alt="" className={'img-fluid'}/>*/}
          {/*        </div>*/}
          {/*    </Col>*/}

          {/*</Row>*/}

          <Row
            style={{
              paddingTop: "5vw",
              paddingBottom: "5vw",
              backgroundColor: ProjectColors.getInstance().isHighContrast
                ? ProjectColors.getInstance().Black
                : ProjectColors.getInstance().Brown,
            }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} md={{ span: 5, offset: 1 }}>
              <h1
                style={{
                  fontFamily: "Active Regular",
                  fontWeight: "bold",
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().White
                    : ProjectColors.getInstance().Green,
                }}
              >
                Conheça os meus trabalhos
              </h1>
              <br />

              <a
                href={"http://www.leticiamiranda.net/"}
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Site Pessoal
              </a>

              <br />
              <a
                href={"https://www.instagram.com/clubedecolagembsb/"}
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Clube de Colagem
              </a>

              <br />
              <a
                href="https://www.instagram.com/leticiadsmiranda/"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Instagram
              </a>

              <br />
              <a
                href="https://www.editorapenalux.com.br/loja/a-suspensao-de-tomie-ohtake"
                style={{
                  color: ProjectColors.getInstance().isHighContrast
                    ? ProjectColors.getInstance().LightBlue
                    : ProjectColors.getInstance().Green,
                  fontSize: 28,
                }}
              >
                Livro A suspensão de Tomie Ohtake
              </a>
            </Col>

            <Col xs={{ span: 12 }} md={{ span: 5 }}>
              <div>
                <video
                  className={"img-fluid m-auto"}
                  style={{ justifyContent: "center", display: "flex" }}
                  controls
                  poster={picture_7}
                >
                  <source src={arte_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </Col>
          </Row>

          <Row
            style={{
              backgroundImage: "url(" + marina + ")",
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: 500,
            }}
            className={"px-3"}
          >
            <Col xs={{ span: 12 }} className={"d-flex "}>
              <h1
                className={"my-4"}
                style={{
                  fontFamily: "Gotham Light",
                  marginLeft: 30,
                  fontSize: "70px",
                  color: "white",
                }}
              >
                Ana
                <img
                  src={Arrow}
                  width={50}
                  onClick={() => navigate("/artist/ana")}
                  alt={"Seta laranja apontando para a direita"}
                />
              </h1>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  );
}
