import {NavigateFunction, useNavigate} from "react-router-dom";

export default class ProjectColors {
    private static instance: ProjectColors;
    isHighContrast: boolean;
    Orange = "#ee731f";
    Green = "#bec28b";
    Brown = "#b04918";
    DarkGreen = "#4d4f20";
    Black = "#000000";
    White = "#ffffff";
    LightGreen = "#13b2a0";
    LightBlue = "#66c5d9";
    VeryLightBlue = "rgba(190,194,139,0.34)";

    private constructor() {
        const isHighContrast = localStorage.getItem("isHighContrast");
        if (isHighContrast) {
            this.isHighContrast = JSON.parse(isHighContrast);
        } else {
            this.isHighContrast = false;
        }
        const storedColors = localStorage.getItem("storedColors");
        if (storedColors) {
            const colors = JSON.parse(storedColors);
            this.Orange = colors.Orange;
            this.Green = colors.Green;
            this.Brown = colors.Brown;
            this.DarkGreen = colors.DarkGreen;
            this.Black = colors.Black;
            this.White = colors.White;
            this.LightGreen = colors.LightGreen;
            this.LightBlue = colors.LightBlue;
        }
    }

    public static getInstance(): ProjectColors {
        if (!ProjectColors.instance) {
            ProjectColors.instance = new ProjectColors();
        }

        return ProjectColors.instance;
    }

    toggleHighContrast(navigate: NavigateFunction) {
        this.isHighContrast = !this.isHighContrast;
        localStorage.setItem("isHighContrast", JSON.stringify(this.isHighContrast));

        if (this.isHighContrast) {
            this.Orange = "#000000";
            this.Green = "#bec28b";
            this.Brown = "#b04918";
            this.DarkGreen = "#4d4f20";
            this.Black = "#000000";
            this.White = "#ffffff";
            this.LightGreen = "#13b2a0";
            this.LightBlue = "#66c5d9";
        } else {
            this.Orange = "#ee731f";
            this.Green = "#bec28b";
            this.Brown = "#b04918";
            this.DarkGreen = "#4d4f20";
            this.Black = "#000000";
            this.White = "#ffffff";
            this.LightGreen = "#13b2a0";
            this.LightBlue = "#66c5d9";
        }

        this.storeCurrentColors();

        navigate(window.location.hash.slice(1), {replace: true});
    }

    storeCurrentColors() {
        localStorage.setItem(
            "storedColors",
            JSON.stringify({
                Orange: this.Orange,
                Green: this.Green,
                Brown: this.Brown,
                DarkGreen: this.DarkGreen,
                Black: this.Black,
                White: this.White,
                LightGreen: this.LightGreen,
                LightBlue: this.LightBlue,
            })
        );
    }
}
