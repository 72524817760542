import MainCustomNavbar from "../../components/MainNavbar";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../../components/Sidebar";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Photo0 from "../../assets/mother_homes/casa_antiga.jpg";
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Column0 from "../../assets/mother_homes/final/column_0.png";
import Column1 from "../../assets/mother_homes/final/column_1.png";
import Column2 from "../../assets/mother_homes/final/column_2.png";
import Column3 from "../../assets/mother_homes/final/column_3.png";
import Column4 from "../../assets/mother_homes/final/column_4.png";
import { log } from "console";
import ProjectColors from "../../Colors";

export default function HousesIndex() {
  const navigate = useNavigate();
  const sliderRef = useRef<HTMLDivElement>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Adjust slider width according to available space
  useEffect(() => {
    const slider = sliderRef.current;
    const sidebar = sidebarRef.current;
    const container = containerRef.current;

    if (!slider || !sidebar || !container) return;

    const observerCallback: ResizeObserverCallback = (
      entries: ResizeObserverEntry[]
    ) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        let sidebarWidth = sidebar.getBoundingClientRect().width;
        let containerWidth = container.getBoundingClientRect().width;
        let sliderWidth = containerWidth - sidebarWidth;
        slider.style.setProperty("width", `${sliderWidth}px`, "important");
        slider.style.width = `${sliderWidth}px !important`;
      });
    };
    const resizeObserver = new ResizeObserver(observerCallback);

    resizeObserver.observe(sidebar);
    resizeObserver.observe(container);

    window.addEventListener("error", (e) => {
      if (
        e.message ===
        "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  });

  return (
    <div
      style={{
        overflow: "hidden",
        height: "100vh",
        width: "100%",
        backgroundColor: ProjectColors.getInstance().isHighContrast
          ? ProjectColors.getInstance().Black
          : ProjectColors.getInstance().VeryLightBlue,
      }}
    >
      <MainCustomNavbar activeTab0={false} activeTab2={true} />
      <main role="main">
        <Container className="memory-page-container" ref={containerRef}>
          <Row xs={2} className="mt-5 g-5 memory-page-row">
            <Col className="sidebar-col" ref={sidebarRef}>
              <Sidebar activeTab={"caixas"} />
            </Col>
            <Col
              className="memory-scrollable-wrapper"
              ref={sliderRef}
              style={{
                backgroundColor: ProjectColors.getInstance().isHighContrast
                  ? ProjectColors.getInstance().DarkGreen
                  : ProjectColors.getInstance().White,
              }}
            >
              <div className="memory-scrollable-image align-content-center">
                <img
                  src={Column4}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                  onClick={(
                    e: React.MouseEvent<HTMLImageElement, MouseEvent>
                  ) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    // @ts-ignore
                    var y = e.clientY - rect.top; //y position within the element.
                    var yPercent = (y / rect.height) * 100;

                    var x = e.clientX - rect.left;
                    var xPercent = (x / rect.width) * 100;

                    console.log(yPercent);

                    if (yPercent < 42) {
                      navigate("/memory/amar");
                    }
                    if (yPercent > 42 && xPercent < 50 && yPercent < 62) {
                      navigate("/memory/libertar");
                    }

                    if (yPercent > 42 && xPercent > 50 && yPercent < 62) {
                      navigate("/memory/habitar");
                    }

                    if (yPercent > 62) {
                      navigate("/memory/festejar");
                    }
                  }}
                />
              </div>

              <div className="memory-scrollable-image align-content-center">
                <img
                  src={Column3}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                  onClick={(
                    e: React.MouseEvent<HTMLImageElement, MouseEvent>
                  ) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    // @ts-ignore
                    var y = e.clientY - rect.top; //y position within the element.
                    var yPercent = (y / rect.height) * 100;

                    console.log(yPercent);
                    if (yPercent < 60) {
                      navigate("/memory/proteger");
                    } else {
                      navigate("/memory/pertencer");
                    }
                  }}
                />
              </div>

              <div className="memory-scrollable-image align-content-center">
                <img
                  src={Column2}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                  onClick={(
                    e: React.MouseEvent<HTMLImageElement, MouseEvent>
                  ) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    // @ts-ignore
                    var y = e.clientY - rect.top; //y position within the element.
                    var yPercent = (y / rect.height) * 100;

                    console.log(yPercent);
                    if (yPercent < 35) {
                      navigate("/memory/gestar");
                    } else {
                      navigate("/memory/ir");
                    }
                  }}
                />
              </div>

              <div className="memory-scrollable-image align-content-center">
                <img
                  src={Column1}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                  onClick={(
                    e: React.MouseEvent<HTMLImageElement, MouseEvent>
                  ) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    // @ts-ignore
                    var y = e.clientY - rect.top; //y position within the element.
                    var yPercent = (y / rect.height) * 100;
                    var x = e.clientX - rect.left;
                    var xPercent = (x / rect.width) * 100;

                    console.log(yPercent);
                    if (yPercent < 35) {
                      navigate("/memory/brincar");
                    } else if (yPercent > 70) {
                      navigate("/memory/confrontar");
                    } else {
                      navigate("/memory/abrigar");
                    }
                  }}
                />
              </div>

              <div className="memory-scrollable-image align-content-center">
                <img
                  src={Column0}
                  alt=""
                  className={"img-fluid float-start memory-img"}
                  useMap={"#column0"}
                  onClick={(
                    e: React.MouseEvent<HTMLImageElement, MouseEvent>
                  ) => {
                    const rect = e.currentTarget.getBoundingClientRect();
                    // @ts-ignore
                    var y = e.clientY - rect.top; //y position within the element.
                    var yPercent = (y / rect.height) * 100;

                    if (yPercent < 47) {
                      navigate("/memory/respeitar");
                    } else {
                      navigate("/memory/costurar");
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}
