import React from "react";

import IndexPageArtist from "./screens/artist/IndexPageArtist";
import {HashRouter, Route, Routes} from "react-router-dom";
import "./css/video.css";
import "./css/select_story.css";
import "./css/nav_bar.css";
import "./css/side_bar.css";
import "./css/custom_button.css";
import "./css/custom_border.css";

import ArtistSelect from "./screens/artist/ArtistSelect";
import IndexPage from "./screens/IndexPage";
import ProfileAna from "./screens/artist/ProfileAna";
import AboutProject from "./screens/AboutProject";
import ProfileTatiane from "./screens/artist/ProfileTatiane";
import ProfileCaisa from "./screens/artist/ProfileCaisa";
import ProfileJulia from "./screens/artist/ProfileJulia";
import ProfileLeticia from "./screens/artist/ProfileLeticia";
import ProfileMarina from "./screens/artist/ProfileMarina";
import ProfileRenata from "./screens/artist/ProfileRenata";
import IndexPageHouses from "./screens/houses/IndexPageHouses";
import MapView from "./screens/houses/MapView";
import PhotosHouse0 from "./screens/houses/house_0/Photos";
import LetterHouse0 from "./screens/houses/house_0/Letter";
import LetterHouse4 from "./screens/houses/house_4/Letter";
import PhotosHouse1 from "./screens/houses/house_1/Photos";
import LetterHouse1 from "./screens/houses/house_1/Letter";
import PhotosHouse2 from "./screens/houses/house_2/Photos";
import LetterHouse2 from "./screens/houses/house_2/Letter";
import PhotosHouse3 from "./screens/houses/house_3/Photos";
import LetterHouse3 from "./screens/houses/house_3/Letter";
import PhotosHouse4 from "./screens/houses/house_4/Photos";
import MainPageMemory from "./screens/memory/MainPageMemory";
import IndexPageMemory from "./screens/memory/IndexPageMemory";
import Respeitar from "./screens/memory/Respeitar";
import Costurar from "./screens/memory/Costurar";
import Afetar from "./screens/memory/Afetar";
import Abrigar from "./screens/memory/Abrigar";
import Fotografar from "./screens/memory/Fotografar";
import Brincar from "./screens/memory/Brincar";
import Ir from "./screens/memory/Ir";
import Proteger from "./screens/memory/Proteger";
import Pertencer from "./screens/memory/Pertencer";
import Amar from "./screens/memory/Amar";
import Libertar from "./screens/memory/Libertar";
import Habitar from "./screens/memory/Habitar";
import Festejar from "./screens/memory/Festejar";
import Confrontar from "./screens/memory/Confrontar";
import HousesIndex from "./screens/memory/HousesIndex";
import TimeAtHome from "./screens/memory/TimeAtHome";
import TimeLine from "./screens/memory/TimeLine";
import Women from "./screens/memory/Women";
import Gestar from "./screens/memory/Gestar";
import Cuidar from "./screens/memory/Cuidar";

function App() {

    return (
        <div className="App" style={{}}>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<IndexPage/>}/>
                    <Route path="/artist" element={<IndexPageArtist/>}/>
                    <Route path="/artist/ana" element={<ProfileAna/>}/>
                    <Route path="/artist/caisa" element={<ProfileCaisa/>}/>
                    <Route path="/artist/julia" element={<ProfileJulia/>}/>
                    <Route path="/artist/leticia" element={<ProfileLeticia/>}/>
                    <Route path="/artist/marina" element={<ProfileMarina/>}/>
                    <Route path="/artist/renata" element={<ProfileRenata/>}/>
                    <Route path="/artist/tatiane" element={<ProfileTatiane/>}/>
                    <Route path="/artist/select" element={<ArtistSelect/>}/>
                    <Route path="/house" element={<IndexPageHouses/>}/>
                    <Route path="/house/map" element={<MapView/>}/>
                    <Route path="/house/house0/photos" element={<PhotosHouse0/>}/>
                    <Route path="/house/house0/letter" element={<LetterHouse0/>}/>
                    <Route path="/house/house1/photos" element={<PhotosHouse1/>}/>
                    <Route path="/house/house1/letter" element={<LetterHouse1/>}/>
                    <Route path="/house/house2/photos" element={<PhotosHouse2/>}/>
                    <Route path="/house/house2/letter" element={<LetterHouse2/>}/>
                    <Route path="/house/house3/photos" element={<PhotosHouse3/>}/>
                    <Route path="/house/house3/letter" element={<LetterHouse3/>}/>
                    <Route path="/house/house4/photos" element={<PhotosHouse4/>}/>
                    <Route path="/house/house4/letter" element={<LetterHouse4/>}/>
                    <Route path="/memory/main" element={<MainPageMemory/>}/>
                    <Route path="/memory" element={<IndexPageMemory/>}/>
                    <Route path="/memory/respeitar" element={<Respeitar/>}/>
                    <Route path="/memory/costurar" element={<Costurar/>}/>
                    <Route path="/memory/afetar" element={<Afetar/>}/>
                    <Route path="/memory/abrigar" element={<Abrigar/>}/>
                    <Route path="/memory/confrontar" element={<Confrontar/>}/>
                    <Route path="/memory/fotografar" element={<Fotografar/>}/>
                    <Route path="/memory/brincar" element={<Brincar/>}/>
                    <Route path="/memory/ir" element={<Ir/>}/>
                    <Route path="/memory/proteger" element={<Proteger/>}/>
                    <Route path="/memory/pertencer" element={<Pertencer/>}/>
                    <Route path="/memory/amar" element={<Amar/>}/>
                    <Route path="/memory/libertar" element={<Libertar/>}/>
                    <Route path="/memory/gestar" element={<Gestar/>}/>
                    <Route path="/memory/habitar" element={<Habitar/>}/>
                    <Route path="/memory/festejar" element={<Festejar/>}/>
                    <Route path="/memory/houses" element={<HousesIndex/>}/>
                    <Route path="/memory/tempoCasa" element={<TimeAtHome/>}/>
                    <Route path="/memory/linhaTempo" element={<TimeLine/>}/>
                    <Route path="/memory/mulheres" element={<Women/>}/>
                    <Route path="/memory/cuidar" element={<Cuidar/>}/>
                    <Route path="/about" element={<AboutProject/>}/>
                    <Route element={<IndexPage/>}/>
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;
